import React, {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import styles from './ModelComparisonPage.module.scss';
import routes from "../routes";
import CustomCheckbox from "../components/CustomCheckbox";
import {API_URLS} from "../config/defaultConfig";
import {DragZoomType} from "../interfaces/d";
import DragZoom from "../components/DragZoom";

const ModelComparisonPage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    let {transaction_id, original_image_url, models: processed_models = {}} = location.state?.data.data ?? {}
    const [selectedModels, setSelectedModels] = useState<string[]>([]);
    const [DragZoomState, setDragZoomState] = useState<DragZoomType>({
        ImageURL: '',
        ContainerSize: {w: 0, h: 0},
        GlobalScale: 1,
        GlobalPos: {x: 0, y: 0},
        MaxScale: 20,
        FactorStep: 0.1
    });

    const HandleDragZoomStateChange = (newData: DragZoomType) => {
        let newGlobalPos = newData.GlobalPos;
        if (newData.GlobalPos.x > 0) newGlobalPos.x = 0;
        if (newData.GlobalPos.x + newData.ContainerSize.w * newData.GlobalScale < newData.ContainerSize.w) {
            newGlobalPos.x = -newData.ContainerSize.w * (newData.GlobalScale - 1);
        }
        if (newData.GlobalPos.y > 0) newGlobalPos.y = 0;
        if (newData.GlobalPos.y + newData.ContainerSize.h * newData.GlobalScale < newData.ContainerSize.h) {
            newGlobalPos.y = -newData.ContainerSize.h * (newData.GlobalScale - 1);
        }

        setDragZoomState(prevState => ({
            ...prevState,
            GlobalPos: newGlobalPos,
            GlobalScale: newData.GlobalScale,
            ContainerSize: newData.ContainerSize
        }));
        setScaleFactor(newData.GlobalScale);
    }

    const handleCheckboxChange = (modelName: string) => {
        setSelectedModels((prevSelected) =>
            prevSelected.includes(modelName)
                ? prevSelected.filter((model) => model !== modelName)
                : [...prevSelected, modelName]
        );
    };

    const handleDownload = async () => {
        const downloadUrl = API_URLS.TransactionDownload(transaction_id);
        const queryParams = selectedModels.map((model) => `models[]=${encodeURIComponent(model)}`).join('&');
        const downloadLink = `${downloadUrl}?${queryParams}`;
        window.open(downloadLink, '_blank');
    };

    const [ScaleFactor, setScaleFactor] = useState(1);

    const handleSliderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newScale = Number(event.target.value);
        const center = {x: DragZoomState.ContainerSize.w / 2, y: DragZoomState.ContainerSize.h / 2};
        const zoom_point = {
            x: center.x,
            y: center.y
        }

        const zoom_target = {
            x: (zoom_point.x - DragZoomState.GlobalPos.x) / DragZoomState.GlobalScale,
            y: (zoom_point.y - DragZoomState.GlobalPos.y) / DragZoomState.GlobalScale
        }

        const newGlobalPos = {
            x: -zoom_target.x * newScale + zoom_point.x,
            y: -zoom_target.y * newScale + zoom_point.y
        };

        setScaleFactor(newScale);
        setDragZoomState(prevState => ({
            ...prevState,
            GlobalScale: newScale,
            GlobalPos: newGlobalPos
        }));
    };

    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.title_wrapper}>
                    <div className={styles.title}>
                        Model Comparison
                    </div>
                    <div className={styles.slider_container}>
                        <div className={styles.scale_factor}>{ScaleFactor.toFixed(1)}X</div>
                        <div>
                            <label htmlFor="scale-slider">Scale Factor</label>
                            <input
                                type="range"
                                id="scale-slider"
                                min="1"
                                max="20"
                                step="0.1"
                                value={ScaleFactor}
                                onChange={handleSliderChange}
                                className={styles.slider}
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.models}>
                    <div className={styles.card}>
                        <div className={styles.model_name}>
                            <>
                                <CustomCheckbox
                                    checked={selectedModels.includes('original')}
                                    onChange={() => handleCheckboxChange('original')}
                                />
                                Original Image
                            </>
                        </div>
                        <DragZoom
                            ImageURL={original_image_url}
                            ContainerSize={DragZoomState.ContainerSize}
                            GlobalScale={DragZoomState.GlobalScale}
                            GlobalPos={DragZoomState.GlobalPos}
                            MaxScale={DragZoomState.MaxScale}
                            FactorStep={DragZoomState.FactorStep}
                            onStateChange={HandleDragZoomStateChange}
                        />
                    </div>
                    {Object.entries(processed_models).map(([modelName, modelData], index) => {
                        let imageUrl = '';
                        if (typeof modelData === 'object' && modelData !== null && 'url' in modelData && typeof modelData.url === 'string') {
                            imageUrl = modelData.url;
                        }

                        return (
                            <div key={modelName} className={styles.card}>
                                <div className={styles.model_name}>
                                    {index >= 0 && (
                                        <>
                                            <CustomCheckbox
                                                checked={selectedModels.includes(modelName)}
                                                onChange={() => handleCheckboxChange(modelName)}
                                            />
                                            {modelName}
                                        </>
                                    )}
                                </div>
                                <DragZoom
                                    ImageURL={imageUrl}
                                    ContainerSize={DragZoomState.ContainerSize}
                                    GlobalScale={DragZoomState.GlobalScale}
                                    GlobalPos={DragZoomState.GlobalPos}
                                    MaxScale={DragZoomState.MaxScale}
                                    FactorStep={DragZoomState.FactorStep}
                                    onStateChange={HandleDragZoomStateChange}
                                />
                            </div>
                        );
                    })}
                </div>
                <div className={styles.buttons}>
                    <button className={styles.back_to_main_button} onClick={() => navigate(routes.modelList)}>Back to Model Page</button>
                    <button
                        onClick={handleDownload}
                        disabled={selectedModels.length === 0}
                        className={`${styles.download_button} ${selectedModels.length > 0 ? styles.button_active : ''}`}>
                        Download
                    </button>
                </div>
            </div>
        </main>
    );
};

export default ModelComparisonPage;
