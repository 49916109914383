import React, {useRef, useState} from 'react';
import styles from './AboutPageEmailForm.module.scss';
import routes from "../routes";
import {postUserContact} from "../services/userService";

const AboutPage: React.FC = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [consent, setConsent] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    const messageTemplates: { [key: string]: string } = {
        "Requesting New AI models": "Github:\n\nHuggingface page:\n\n[Optional] Paper:",
        "Enterprise - Requesting dedicated server allocation": "",  // empty template
        "Enterprise - Price inquiries": "", // empty template
        "General / Other Questions": "" // empty template
    };

    const handleTemplateChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        setSelectedTemplate(selectedValue);
        setMessage(messageTemplates[selectedValue]);

        if (textareaRef.current) {
            textareaRef.current.focus();
        }
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();

        const user_contact_message = {
            "name": name,
            "email": email,
            "message": message
        };

        const message_send_result = await postUserContact(user_contact_message);
        setEmailSent(message_send_result);
    };

    return (
        <div className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.about_page_title}>
                    About CNAPS.AI
                </div>

                <div className={styles.about_item_container}>
                    <div className={styles.about_item_title}>
                        Our motivations
                    </div>
                    <div className={styles.about_item_description}>
                        Large AI models, such as LLMs, LMMs, and other foundational models, are costly to train and deploy for inference tasks.
                        In contrast, the human brain operates with remarkable efficiency, outperforming current large AI models in many general tasks.
                        Interestingly, the human brain often engages only specific pathways, rather than its full capacity, to complete particular tasks.
                        <br/><br/>
                        There are now over 1 million pre-trained AI models. Our initiative aims to leverage these small and specialized AI models
                        to deliver state-of-the-art AI capabilities compared to the large AI models without incurring significant training / inference costs.
                    </div>
                </div>

                <div className={styles.about_item_container}>
                    <div className={styles.about_item_title}>
                        Our goals
                    </div>
                    <div className={styles.about_item_description}>
                        Our software allows AI models to be seamlessly connected as long as their inputs and outputs are compatible.
                        Without coding, users can accomplish various tasks using pre-trained AI models.
                        <br/><br/>
                        We will soon provide the functionality of optimizing the AI flow and reduce domain gap issues by student-teacher model and fine-tuning.
                        The interconnected small but specialized AI models will provide the SOTA or better than a single large AI model.
                        We believe that we can eventually create artificial brains by connecting and mapping many, small, and diverse AI models.
                    </div>
                </div>

                <div className={styles.about_form_container}>
                    <div className={styles.about_form_title}>
                        Contact us
                    </div>

                    {emailSent ? (
                        <div className={styles.email_sent}>
                            <h2>Thank you for reaching out to us!</h2>
                            <p>Your email has been sent successfully. We appreciate you taking the time to contact us.
                                Our team will review your message and get back to you as soon as possible. We strive to
                                respond promptly to all inquiries.</p>
                            <p>Best regards,</p>
                            <p>The CNAPS.AI Team</p>
                        </div>
                    ) : (
                        <form onSubmit={handleSubmit}>
                            <div className={styles['form-group']}>
                                <label htmlFor="name">Name</label>
                                <input
                                    type="text"
                                    id="name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                            </div>
                            <div className={styles['form-group']}>
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <div className={styles['form-group']}>
                                <label htmlFor="template">Select a template</label>
                                <select
                                    id="template"
                                    name="template"
                                    value={selectedTemplate}
                                    onChange={handleTemplateChange}
                                    required
                                >
                                    <option value="">Please select...</option>
                                    {Object.keys(messageTemplates).map((templateKey) => (
                                        <option key={templateKey} value={templateKey}>
                                            {templateKey}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className={styles['form-group']}>
                                <label htmlFor="message">Message</label>
                                <textarea
                                    id="message"
                                    name="message"
                                    value={message}
                                    onChange={(e) => setMessage(e.target.value)}
                                    required
                                    ref={textareaRef}
                                ></textarea>
                            </div>
                            <div className={styles['checkbox-group']}>
                                <input
                                    type="checkbox"
                                    id="consent"
                                    name="consent"
                                    checked={consent}
                                    onChange={(e) => setConsent(e.target.checked)}
                                    required
                                />
                                <label htmlFor="consent">I agree to the processing of my data in accordance with the <a
                                    href={routes.privacyPolicy} target="_blank" rel="noopener noreferrer">privacy
                                    policy</a>.</label>
                            </div>
                            <button type="submit">Submit</button>
                        </form>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AboutPage;
