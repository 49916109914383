import axios, {AxiosInstance} from 'axios';
import {APP_BASE_URL} from "../config/defaultConfig";

axios.defaults.withCredentials = true;

const ApiClient: AxiosInstance = axios.create({
    baseURL: APP_BASE_URL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    }
});

ApiClient.interceptors.request.use(config => {
    const session_id = sessionStorage.getItem('session_id');
    if (session_id) {
        config.headers['session_id'] = session_id;
    }
    return config;
}, error => {
    return Promise.reject(error);
});

if (process.env.NODE_ENV === 'development') {
    import('../mocks/setupMock').then(setupMock => {
        setupMock.default(ApiClient);
        console.log('## Axios Mock Adapter Setup');
    });
}

export default ApiClient;
