import ApiClient from './apiClient';
import {API_URLS} from "../config/defaultConfig";
import axios from "axios";

export const CheckoutSubscription = async (plan_id: string): Promise<{ id: string }> => {
    try {
        const response = await ApiClient.get(API_URLS.SubscriptionCheckout(plan_id));
        return response.data;
    } catch (e) {
        console.error('Error SubscriptionCheckout:', e);
        if (axios.isAxiosError(e)) {
            if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                throw new Error(e.response.data.error.msg);
            }
        }
        throw new Error('An unknown error occurred');
    }
}

export const SubscriptionCurrentPlan = async (): Promise<string | undefined> => {
    try {
        const response = await ApiClient.get(API_URLS.SubscriptionCurrentPlan);
        return response.data.plan_id;
    } catch (e) {
        console.error('Error SubscriptionCheckout:', e);
        if (axios.isAxiosError(e)) {
            if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                throw new Error(e.response.data.error.msg);
            }
        }
        // throw new Error('An unknown error occurred');
    }
}
