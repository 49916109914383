import {CustomNodeType, IFlowNodeSource} from "../../interfaces/d";
import React from "react";

interface IDragData {
    node_name: string;
    node_type: CustomNodeType;
    model_id: string;
    category: string;
    related_links: Record<string, string>;
}

export const onDragStart = (event: React.DragEvent<HTMLElement>, data: string) => {
    event.dataTransfer.setData('application/reactflow', data);
    event.dataTransfer.effectAllowed = 'move';
};

export const onDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
};

export const parseDragData = (data: string): IDragData => {
    const model_data: IFlowNodeSource = JSON.parse(data);
    let node_type = CustomNodeType.UNKNOWN;
    // console.log('model_data.category', model_data.category);
    switch (model_data.category.toLowerCase()) {
        case 'input':
            // console.log('model_data.node_type', model_data.node_type);
            switch (model_data.node_type.toLowerCase()) {
                case 'Txt2ImgInput'.toLowerCase():
                    node_type = CustomNodeType.TXT2IMG_INPUT
                    break;
                case 'ImageLoader'.toLowerCase():
                    node_type = CustomNodeType.IMAGE_LOADER;
                    break;
                case "TextInput".toLowerCase():
                    node_type = CustomNodeType.TEXT_INPUT;
                    break;
                case "FloatInput".toLowerCase():
                    node_type = CustomNodeType.FLOAT_INPUT;
                    break;
                default:
                    console.error(`unknown INPUT node type ${model_data.node_type}`)
            }
            break;
        case 'output':
            switch (model_data.node_type.toLowerCase()) {
                case "TextViewer".toLowerCase():
                    node_type = CustomNodeType.TEXT_VIEWER;
                    break;
                case 'ImageViewer'.toLowerCase():
                    node_type = CustomNodeType.IMAGE_VIEWER;
                    break;
                default:
                    console.error(`unknown OUTPUT node type ${model_data.node_type}`)
            }
            break;
        default:
            node_type = CustomNodeType.AI_MODEL;
    }

    return {
        model_id: model_data.model_id,
        node_type: node_type,
        node_name: model_data.node_name,
        category: model_data.category,
        related_links: model_data.related_links
    };
};
