import React, {useState} from "react";
import {IFlowNodeSource} from "../../interfaces/d";
import styles from "../../views/AIFlow.module.scss";
import {onDragStart} from "./NodeListComponent";

const classPerCategory: { [key: string]: string } = {
    "deblur": "deblur",
    "denoise": "denoise",
    "sisr": "sisr",
    "txt2img": "txt2img",
};

export const ModelList: React.FC<{ _filteredModelData: IFlowNodeSource[] }> = ({_filteredModelData}) => {
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);
    const [overlayPosition, setOverlayPosition] = useState<{ top: number; left: number } | null>(null);

    const categories: string[] = Array.from(new Set(_filteredModelData.map(model => model.category)));

    const handleCategoryClick = (category: string, event: React.MouseEvent) => {
        const overlayMaxHeight = 450;
        const overlayPadding = 20;
        const cursorY = event.clientY;
        const cursorX = event.clientX;
        const viewportHeight = window.innerHeight;
        const overlayWidth = 300;


        const overlayItemHeight = 50;
        const itemCount = _filteredModelData.filter(model => model.category === category).length;
        const overlayHeight = Math.min(
            overlayMaxHeight,
            itemCount * overlayItemHeight + overlayPadding * 2
        );

        let top = cursorY;
        let left = cursorX + 40;


        if (top + overlayHeight > viewportHeight) {
            top = viewportHeight - overlayHeight - 10;
        }

        setOverlayPosition({top, left});
        setSelectedCategory(category === selectedCategory ? null : category);
    };

    const filteredModels = _filteredModelData.filter(model => model.category === selectedCategory);

    const handleOverlayDragLeave = () => {
        setSelectedCategory(null); // 오버레이 숨기기
    };

    return (
        <div>
            <ul className={styles.model_categories}>
                {categories.map((category, index) => {
                    const categoryClass = classPerCategory[category.toLowerCase()];

                    return (
                        <li
                            key={index}
                            className={`${styles.li_box}`}
                            onClick={(event) => handleCategoryClick(category, event)}
                        >
                            <div className={`${styles.dot} ${styles[categoryClass]}`}>
                                <div className={styles.inner_dot}></div>
                            </div>
                            {category}
                        </li>
                    );
                })}
            </ul>
            {selectedCategory && overlayPosition && (
                <div
                    className={styles.model_overlay}
                    style={{
                        top: `${overlayPosition.top}px`,
                        left: `${overlayPosition.left}px`,
                    }}
                    onDragLeave={handleOverlayDragLeave}
                >
                    <ul>
                        {filteredModels.map((model, index) => {
                            const serialized_data = JSON.stringify({
                                node_name: model.node_name,
                                node_type: model.node_type,
                                model_id: model.model_id,
                                category: model.category,
                            });
                            return (
                                <li
                                    draggable
                                    onDragStart={(event) => onDragStart(event, serialized_data)}
                                    className={`${styles.li_box}`}
                                    key={index}
                                >
                                    {model.node_name}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            )}

        </div>
    );
};
