import React, {useEffect, useState} from 'react';
import styles from './DashboardPage.module.scss';
import {useNavigate} from 'react-router-dom';
import IsLoading from "../components/IsLoading";
import {fetchUserDashboardData} from "../services/userService";
import {ProfileEditModalStage, TransactionTransaction, UserFavorite} from "../interfaces/d";
import Modal from "../components/Modal";
import ProfileEdit from "../components/ProfileEdit";
import {useAuth} from "../services/authService";
import routes from "../routes";
import DashboardFavoriteCards from "../components/DashboardFavoriteCards";

const DashboardPage = () => {
    const navigate = useNavigate();
    const auth = useAuth();

    const [favoriteCards, setFavoriteCards] = useState<UserFavorite[]>([]);
    const [transactions, setTransactions] = useState<TransactionTransaction[]>([]);
    const [expandedTransactionId, setExpandedTransactionId] = useState<string | null>(null);
    const [favoriteTotalPage, setFavoriteTotalPage] = useState(1)
    const [favoriteCurrentPage, setFavoriteCurrentPage] = useState(1)
    const [favoriteModelsPerPage, setFavoriteModelsPerPage] = useState(1)
    const [favoriteTotalModels, setFavoriteTotalModels] = useState(0);

    useEffect(() => {
        if (!auth.loading && (!auth.user || !auth.isLoggedIn)) {
            navigate(routes.signIn);
        }
    }, [auth, navigate]);

    // Dashboard data fetch
    useEffect(() => {
        fetchUserDashboardData().then(data => {
            // Favorite models
            setFavoriteCards(data.data.favorites.favorites);
            setFavoriteTotalPage(data.data.favorites.page.total);
            setFavoriteCurrentPage(data.data.favorites.page.current);
            setFavoriteTotalModels(data.data.favorites.total_count);
            setFavoriteModelsPerPage(data.data.favorites.page.size);

            // Transaction Activity
            setTransactions(data.data.transactions);
        }).catch(error => {
            console.error(error);
            navigate(routes.signIn);
        });
    }, [navigate]);

    // Modal - Edit profile
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalStage, setModalStage] = useState<ProfileEditModalStage>(ProfileEditModalStage.EDIT);

    useEffect(() => {
        if (modalStage === ProfileEditModalStage.OFF) {
            closeModal();
        }

    }, [modalStage]);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalStage(ProfileEditModalStage.EDIT);
    };
    ////

    const [transactionsByMonth, setTransactionsByMonth] = useState<{
        [key: string]: { [key: string]: typeof transactions }
    }>({});

    // useEffect(() => {
    //     const sortedTransactions = transactions ? transactions.sort((a, b) => b.created - a.created) : null;
    //     const newTransactionsByMonth: { [key: string]: { [key: string]: typeof transactions } } = {};
    //
    //     sortedTransactions && sortedTransactions.forEach(transaction => {
    //         const date = new Date(transaction.created);
    //         const monthKey = date.toLocaleString('eng', {month: 'long', year: 'numeric'});
    //         const dayKey = date.toLocaleString('eng', {day: '2-digit', month: 'short'});
    //
    //         if (!newTransactionsByMonth[monthKey]) {
    //             newTransactionsByMonth[monthKey] = {};
    //         }
    //
    //         if (!newTransactionsByMonth[monthKey][dayKey]) {
    //             newTransactionsByMonth[monthKey][dayKey] = [];
    //         }
    //
    //         newTransactionsByMonth[monthKey][dayKey].push(transaction);
    //     });
    //
    //     setTransactionsByMonth(newTransactionsByMonth);
    // }, [transactions]);

    const toggleTransactionDetail = (id: string) => {
        setExpandedTransactionId(expandedTransactionId === id ? null : id);
    };

    if (auth.loading) {
        return <IsLoading/>;
    }

    return (
        <div className={styles.main}>
            <div className={styles.main_wrapper}>
                <div className={styles.profile_wrapper}>
                    <div className={styles.profile_header}>
                        <img
                            src={auth?.user?.profile?.avatar?.original || '/images/profile_avatar.svg'}
                            alt='avatar' className={styles.avatar}/>
                        <div className={styles.name}>
                            {auth?.user?.profile?.name || 'not yet'}
                        </div>
                        <div className={styles.job_title}>{auth?.user?.profile?.job}</div>
                    </div>

                    <div className={styles.profile_body}>
                        <div className={styles.stats}>
                            <div className={styles.stat}>
                                <div
                                    className={styles.number}>{auth?.user?.stats?.models ? auth.user.stats.models : 0}</div>
                                <div className={styles.label}>Models</div>
                            </div>
                            <div className={styles.stat}>
                                <div
                                    className={styles.number}>{auth?.user?.stats?.followers ? auth.user.stats.followers : 0}</div>
                                <div className={styles.label}>Followers</div>
                            </div>
                            <div className={styles.stat}>
                                <div
                                    className={styles.number}>{auth?.user?.stats?.following ? auth.user.stats.following : 0}</div>
                                <div className={styles.label}>Following</div>
                            </div>
                        </div>
                        <div className={styles.bio}>
                            {auth?.user?.profile?.job}
                        </div>

                        <button className={styles.edit_button} onClick={openModal}>Edit profile</button>

                        {auth?.user?.profile?.location && (
                            <div className={styles.location}>
                                <img src='/images/icon_location.svg' alt='location'/>
                                <span>{auth?.user?.profile?.location}</span>
                            </div>
                        )}

                        {auth?.user?.profile?.home && (
                            <div className={styles.location}>
                                <img src='/images/icon_website.svg' alt='location'/>
                                <span>{auth?.user?.profile?.home}</span>
                            </div>
                        )}

                        {auth!.user!.is_public ?
                            <div className={styles.permission}>
                                <img src='/images/icon_public.svg' alt='public'/>
                            </div>
                            : <></>
                        }

                        <div className={styles.achievements}>
                            {/*<img className={styles.icon} src='/images/badge.png' alt='achievement' />*/}
                        </div>
                    </div>
                </div>
                <div className={styles.dashboard_wrapper}>
                    <div className={styles.title}>
                        Dashboard
                    </div>

                    <div className={styles.cards_header}>
                        <div className={styles.left}>Favorite models</div>
                        <div className={styles.right}></div>
                    </div>

                    <DashboardFavoriteCards initialData={{
                        current_page: favoriteCurrentPage,
                        total_page: favoriteTotalPage,
                        total_models: favoriteTotalModels,
                        models_per_page: favoriteModelsPerPage,
                        favorite_models: favoriteCards
                    }}/>

                    <div className={styles.activity_wrapper}>
                        <div className={styles.header}>Activity</div>
                        {Object.entries(transactionsByMonth).map(([month, days]) => (
                            <React.Fragment key={month}>
                                <div className={styles.timeline_container}>
                                    <div className={styles.timeline_entry}>
                                        <div className={styles.timeline_month}><span>{month}</span></div>
                                        <div className={styles.timeline_bar}></div>
                                    </div>
                                </div>
                                <div className={styles.day_container_wrapper}>
                                    {Object.entries(days).map(([day, dayTransactions]) => (
                                        <div key={day} className={styles.day_container}>
                                            <div className={styles.day_image_container}>
                                                <img src="/images/dashboard_icon_activity_stack.svg" alt="Icon"/>
                                            </div>

                                            <div className={styles.day}>
                                                <div className={styles.generated_models_container}>
                                                    <span>Generated {dayTransactions.length} models</span>
                                                </div>
                                                <span className={styles.right_day}>{day.replace(' ', '')}</span>
                                            </div>

                                            <ul className={styles.transactions_list}>
                                                {dayTransactions.map((transaction, index) => (
                                                    <React.Fragment key={transaction.id}>
                                                        <li className={styles.transaction}>
                                                            <div className={styles.transaction_info}
                                                                 onClick={() => toggleTransactionDetail(transaction.id)}>
                                                                <span className={styles.model_name}>
                                                                    {transaction.properties.model_unique_name}
                                                                </span>
                                                                {!transaction.is_public && (
                                                                    <img
                                                                        src="/images/dashboard_icon_activity_private.svg"
                                                                        alt="Private"
                                                                        className={styles.public_private_icon}/>
                                                                )}
                                                            </div>
                                                            <span className={styles.download_icon}>
                                                                <img src="/images/dashboard_icon_activity_download.svg"
                                                                     alt="Download"/>
                                                            </span>
                                                        </li>
                                                        {expandedTransactionId === transaction.id && (
                                                            <li className={styles.transaction_detail}>
                                                                <div className={styles.detail_image}>
                                                                    <img src='/images/dashboard_sample_girl.svg'
                                                                         alt="Detail"/>
                                                                </div>
                                                                <div className={styles.detail_content}>
                                                                    <p><span className={styles.detail_content_title}>Prompt :</span> {transaction.properties.prompt}
                                                                    </p>
                                                                    <p><span className={styles.detail_content_title}>Negative
                                                                        Prompt :</span> {transaction.properties.negativePrompt}
                                                                    </p>
                                                                    <a href="?"
                                                                       style={{color: '#5162F9'}}>{transaction.properties.model_unique_name}</a>
                                                                    <div className={styles.additional_details}>
                                                                        <div className={styles.detail_row}>
                                                                            <span
                                                                                className={styles.detail_title}>Steps:</span>
                                                                            <span>{transaction.properties.advanced.step}</span>
                                                                        </div>
                                                                        <div className={styles.detail_row}>
                                                                            <span className={styles.detail_title}>Image size:</span>
                                                                            <span>{transaction.properties.advanced.image_size.w} x {transaction.properties.advanced.image_size.h}</span>
                                                                        </div>
                                                                        <div className={styles.detail_row}>
                                                                            <span
                                                                                className={styles.detail_title}>Sampler:</span>
                                                                            <span>{transaction.properties.advanced.sampler}</span>
                                                                        </div>
                                                                        <div className={styles.detail_row}>
                                                                            <span
                                                                                className={styles.detail_title}>Scale:</span>
                                                                            <span>{transaction.properties.advanced.scale}</span>
                                                                        </div>
                                                                        <div className={styles.detail_row}>
                                                                            <span className={styles.detail_title}>Downscale factor:</span>
                                                                            <span>{transaction.properties.advanced.downscale_factor}</span>
                                                                        </div>
                                                                        <div className={styles.detail_row}>
                                                                            <span
                                                                                className={styles.detail_title}>Seed:</span>
                                                                            <span>{transaction.properties.advanced.seed}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        )}
                                                    </React.Fragment>
                                                ))}
                                            </ul>
                                        </div>
                                    ))}
                                </div>
                            </React.Fragment>
                        ))}
                        <button className={styles.show_more_button}>Show more activity</button>
                    </div>
                </div>
            </div>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                {modalStage === ProfileEditModalStage.EDIT && <ProfileEdit setModalStage={setModalStage}/>}
            </Modal>
        </div>
    );
}

export default DashboardPage;
