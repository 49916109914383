import React, {useEffect, useRef, useState} from 'react';
import {createColumnHelper, flexRender, getCoreRowModel, useReactTable} from '@tanstack/react-table';
import styles from './ModelListPage.module.scss';
import {Category, IModelModel, UploadModalStage} from '../interfaces/d';
import {fetchModelsAndCategories} from '../services/modelService';
import {toggleModelFavorite} from '../services/userService';
import CategorySelector from "../components/CategorySelector";
import Modal from '../components/Modal';
import ImageUpload from '../components/ImageUpload';
import ImageGenerateProcess from '../components/ImageGenerateProcess';
import SentMail from '../components/SendMail';
import {DEFAULT_APP_CONFIG} from "../config/defaultConfig";
import {useAuth} from "../services/authService";
import {useNavigate} from "react-router-dom";
import routes from "../routes";

declare module 'react' {
    interface CSSProperties {
        '--hover-background-image'?: string;
    }
}

const images = {
    removeTag: require('../asset/remove_tag_x.svg').default,
    iconSearch: require('../asset/search_icon.svg').default,
    list: require('../asset/toggle_list_list.svg').default,
    album: require('../asset/toggle_list_album.svg').default,
    cloud: require('../asset/cloud.svg').default,
    cardNoImage: require('../asset/card-no-image.svg').default,
    favorite_in_image: require('../asset/favorite_in_image.svg').default,
};

const columnHelper = createColumnHelper<IModelModel>();

const ModelListPage = () => {
    const [modelData, setModelData] = useState<IModelModel[]>([]);
    const [loading, setLoading] = useState(true);
    const [modelsCount, setModelsCount] = useState(0);
    const [selectedRowIds, setSelectedRowIds] =
        useState<Record<string, boolean>>({});  // key is IModelModel.id - not in metadata
    const [menuCategories, setMenuCategories] = useState<{ [key: string]: Category } | null>(null);

    // Modal
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalStage, setModalStage] = useState<UploadModalStage>(UploadModalStage.UPLOAD);
    const [TransactionId, setTransactionId] = useState('');
    const selectedModelsCount = Object.keys(selectedRowIds).length;
    const isButtonDisabled = selectedModelsCount === 0;

    const user = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (modalStage === UploadModalStage.OFF) {
            closeModal();
        }

    }, [modalStage]);
    const openModal = () => {
        if (!isButtonDisabled) {
            setIsModalOpen(true);
        }
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalStage(UploadModalStage.UPLOAD);
    };

    const [selectedMainCategory, setSelectedMainCategory] = useState<string | null>('SISR');
    // const [selectedSubCategory, setSelectedSubCategory] = useState<string | null>(null);

    const handleMainCategorySelect = (categoryName: string) => {
        setSelectedMainCategory(categoryName);
        // setSelectedSubCategory(null);
        setSelectedRowIds({});
        if (isFilterFocused) {
            setTimeout(() => {
                filterInputRef.current?.focus();
            }, 100);

        }
    };

    // Filter
    const [filterText, setFilterText] = useState<string>('');
    const [isFilterFocused, setIsFilterFocused] = useState(false);
    const filterInputRef = useRef<HTMLInputElement>(null);
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilterText(event.target.value);
    };

    const filteredModelData = modelData.filter((model: IModelModel) => {
        const matchesMainCategory = selectedMainCategory
            ? model.category.main.includes(menuCategories?.[selectedMainCategory]?.include_string_from_model_metadata_name ?? "")
            : true;

        const matchesFilterText = model.model_name.toLowerCase().includes(filterText.toLowerCase()) ||
            model.model_name.toLowerCase().includes(filterText.toLowerCase());

        return matchesMainCategory && (matchesFilterText || selectedRowIds[model.id]);
    });

    useEffect(() => {
        setModelsCount(filteredModelData.length);
    }, [filteredModelData.length]);

    const tableColumns = [
        columnHelper.accessor('model_name', {
            header: () => 'Model',
            cell: info => (
                <>
                    {info.getValue()}
                </>
            ),
        }),
        columnHelper.accessor('license.code', {
            header: () => 'Code License',
            cell: info => info.getValue() ? info.getValue() : '-',
        }),
        columnHelper.accessor('license.data', {
            header: () => 'Dataset License',
            cell: info => info.getValue() ? info.getValue() : '-',
        }),
        columnHelper.accessor('license.owner', {
            header: () => 'Model Owner',
            cell: info => info.getValue() ? info.getValue() : '-',
        }),
        columnHelper.accessor('year', {
            header: () => 'Date',
            cell: info => info.getValue(),
        }),
        columnHelper.accessor('try', {
            header: () => 'Try',
        }),
        columnHelper.accessor('favorites', {
            header: () => (<div className={styles.favorite}></div>),
            cell: info => {
                const isLoading = loadingStates[info.row.original.id];

                if (isLoading) {
                    return <div className={styles.spinner}></div>;
                } else {
                    return info.getValue() ? (
                        <img src='/images/favorite_checked.svg'
                             className={styles.favorite_checked}
                             alt='favorite'
                             onClick={() => {
                                 handleFavoriteToggleClick(info.row.original.id, !info.getValue());
                             }}
                        />
                    ) : (
                        <img src='/images/favorite_unchecked.svg'
                             className={styles.favorite_unchecked}
                             alt='favorite'
                             onClick={() => {
                                 handleFavoriteToggleClick(info.row.original.id, !info.getValue());
                             }}
                        />
                    );
                }
            },
        }),
    ]
    const cardColumns = [
        columnHelper.accessor('model_name', {
            cell: info => (
                <>
                    {info.getValue()}
                </>
            ),
        }),
        columnHelper.accessor('image_name', {
            cell: info => (
                <div className={styles.image_container}>
                    <img className={styles.card_image} src={images.cardNoImage} alt={info.getValue()} />
                    <div className={styles.likes}>
                        <img src={images.favorite_in_image} alt='Likes' /> {info.row.original.likes}
                    </div>
                </div>
            ),
        }),
        columnHelper.accessor('model_name', {
            cell: info => (
                <div className={styles.title_container}>
                    <div className={styles.title}>Title</div>
                    <div className={styles.title_desc}>{info.getValue()}</div>
                </div>
            )
        }),
        columnHelper.accessor('license.code', {
            cell: info => (
                <div className={styles.title_container}>
                    <div className={styles.title}>License</div>
                    <div className={styles.title_desc}>{info.getValue()}</div>
                </div>
            )
        }),
        columnHelper.accessor('try', {
            cell: info => {
                const value = info.renderValue();
                const formattedValue = typeof value === 'number' ? value >= 1000 ? (value / 1000).toFixed(1) + 'k' : value.toString() : value;

                return (
                    <div className={styles.title_container}>
                        <div className={styles.title}>Try</div>
                        <div className={styles.title_desc}>
                            {formattedValue}
                        </div>
                    </div>
                );
            }
        }),
        columnHelper.accessor('year', {
            cell: info => (
                <div className={styles.title_container}>
                    <div className={styles.title}>Date</div>
                    <div className={styles.title_desc}>{info.getValue()}</div>
                </div>
            )
        }),
        columnHelper.accessor('id', {
            cell: () => (
                <div className={styles.view_more}>
                    View More
                </div>
            )
        }),
    ]

    const [viewType, setViewType] = useState('table');
    const currentColumns = viewType === 'table' ? tableColumns : cardColumns;

    const [loadingStates, setLoadingStates] = useState<{ [key: string]: boolean }>({});

    const handleFavoriteToggleClick = (rowId: string, toggle: boolean) => {
        if (!user.isLoggedIn) {
            navigate(routes.signIn);
            return;
        }

        if (loadingStates[rowId]) return;

        setLoadingStates(prevStates => ({...prevStates, [rowId]: true}));

        toggleModelFavorite(rowId, toggle).then(() => {
            setModelData(currentData =>
                currentData.map(model => model.id === rowId ? {...model, favorite: toggle} : model)
            );
        }).catch(error => {
            console.log('catch error', error);
        }).finally(() => {
            setLoadingStates(prevStates => ({...prevStates, [rowId]: false}));
        });
    }

    const handleRowSelectionChange = (rowId: string) => {
        setSelectedRowIds((prevSelectedRowIds) => {
            const alreadySelected = prevSelectedRowIds[rowId];
            const selectedCount = Object.keys(prevSelectedRowIds).length;

            if (alreadySelected) {
                const {[rowId]: removed, ...rest} = prevSelectedRowIds;
                return rest;
            }
            else if (selectedCount < DEFAULT_APP_CONFIG.MAX_SELECTED_MODELS) {
                return { ...prevSelectedRowIds, [rowId]: true };
            }
            return prevSelectedRowIds;
        });
    };

    useEffect(() => {
        const fetchData = async () => {
            const { models, categories } = await fetchModelsAndCategories();
            setModelData(models);
            setMenuCategories(categories);
            setModelsCount(models.length);
            setLoading(false);
        };

        fetchData();
    }, []);

    // const renderSelectedModelTags = () => {
    //     const selectedModelNames = Object.keys(selectedRowIds)
    //         .filter(key => selectedRowIds[key])
    //         .map(key => {
    //             const model = modelData.find(model => model.id === key);
    //             return model ? model.model_name : '';
    //         })
    //         .filter(name => name != null);
    //
    //     const removeModelTag = (modelName: string) => {
    //         const modelIdToRemove = modelData.find(model => model.model_name === modelName)?.id;
    //         if (modelIdToRemove) {
    //             setSelectedRowIds(prevSelectedRowIds => {
    //                 const {[modelIdToRemove]: removed, ...rest} = prevSelectedRowIds;
    //                 return rest;
    //             });
    //         }
    //     };
    //
    //     const TagButton = ({ name, onRemove }: { name: string; onRemove: (name: string) => void }) => (
    //         <button onClick={() => onRemove(name)}>{name} <img src={images.removeTag} alt='{name}' /></button>
    //     );
    //
    //     // if (selectedModelNames.length === 0) {
    //     //     return <span>Maximum {DEFAULT_APP_CONFIG.MAX_SELECTED_MODELS} selected Models</span>;
    //     // }
    //
    //     return (
    //         <div className={styles.selected_models_tags}>
    //             <span
    //                 style={{paddingRight: "20px"}}>Maximum {DEFAULT_APP_CONFIG.MAX_SELECTED_MODELS} selected Models</span>
    //
    //             {selectedModelNames.map(modelName => (
    //                 <TagButton key={modelName} name={modelName} onRemove={removeModelTag}/>
    //             ))}
    //
    //         </div>
    //     );
    // };

    const TableViewComponent = React.memo<{ data: IModelModel[] }>(
        ({ data }) => {
        const table = useReactTable({
            data,
            columns: currentColumns,
            getCoreRowModel: getCoreRowModel(),
            state: {
                rowSelection: selectedRowIds,
            },
            onRowSelectionChange: setSelectedRowIds,
        })

        if (!data || data.length === 0) {
            return <div>No data available.</div>;
        }

        return (
            <div className={styles.table_container}>
                <table>
                    <thead>
                    {table.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                            {headerGroup.headers.map(header => (
                                <th key={header.id}>
                                    {header.isPlaceholder
                                        ? null
                                        : flexRender(
                                            header.column.columnDef.header,
                                            header.getContext()
                                        )}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody>
                    {table.getRowModel().rows.map(row => (
                        <tr key={row.id}>
                            {row.getVisibleCells().map(cell => (
                                <td key={cell.id}>
                                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                </td>
                            ))}
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        );
    })

    const CardViewComponent = React.memo<{ data: IModelModel[] }>(
        ({ data }) => {
        const table = useReactTable({
            data,
            columns: currentColumns,
            getCoreRowModel: getCoreRowModel(),
        })

        return (
            <div className={styles.card_container}>
                {table.getRowModel().rows.map(row => (
                    <div className={styles.card} key={row.id}>
                        {row.getVisibleCells().map(cell => (
                            <div key={cell.id}>
                                {flexRender(cell.column.columnDef.cell, cell.getContext())}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    })

    // noinspection PointlessBooleanExpressionJS
    // const renderModelNameCheckbox = (rowId: string, modelName: string) => (
    //     <div className={styles.model_name}>
    //         <CustomCheckbox
    //             checked={!!selectedRowIds[rowId]}  // undefine to false
    //             onChange={() => handleRowSelectionChange(rowId)}
    //         />
    //         {modelName}
    //     </div>
    // );

    return (
        <div className={styles.main}>
            <div className={styles.models_container}>
                {menuCategories && (
                    <CategorySelector
                        selectedMainCategory={selectedMainCategory}
                        onSelectMainCategory={handleMainCategorySelect}
                        categories={menuCategories}
                    />
                )}
                <div className={styles.models}>
                    <div className={styles.filter}>
                        <div className={styles.model_name}>Model</div>
                        <div className={styles.counter}>{modelsCount}</div>

                        <div className={styles.search}>
                            <img src={images.iconSearch} className={styles.search_icon} alt='search' />
                            <input type='text' placeholder='Filter by name'
                                   onChange={handleFilterChange} value={filterText} ref={filterInputRef}
                                   onFocus={() => {setIsFilterFocused(true);}}
                                   onBlur={() => {setIsFilterFocused(false)}}
                            />
                        </div>

                        <div className={styles.select_list_type}>
                            <img src={images.list} alt='Table Type Button' onMouseDown={() => setViewType('table')}/>
                            <img src={images.album} alt='Card Type Button' onMouseDown={() => setViewType('card')}/>
                        </div>
                    </div>
                    {
                        loading ? (
                            <div>Loading...</div>
                        ) : (
                            viewType === 'table' ? <TableViewComponent data={filteredModelData} /> : <CardViewComponent data={filteredModelData} />
                        )
                    }
                </div>
            </div>

            <Modal isOpen={isModalOpen} onClose={closeModal}>
                {modalStage === UploadModalStage.UPLOAD && <ImageUpload setModalStage={setModalStage} selectedModelIds={selectedRowIds} setTransactionId={setTransactionId} />}
                {modalStage === UploadModalStage.GENERATE_PROCESS && <ImageGenerateProcess setModalStage={setModalStage} TransactionId={TransactionId} />}
                {modalStage === UploadModalStage.SENT_MAIL && <SentMail setModalStage={setModalStage} />}
            </Modal>
        </div>
    );
}

export default ModelListPage;
