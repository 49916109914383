import React from 'react';
import {Parameter} from '../../interfaces/d';
import styles from './DefaultCustomNode.module.scss'

interface ParametersFormProps {
    parameters: Record<string, Parameter>;
    onChange: (name: string, value: any) => void; // Consider specifying a more specific type than `any` if possible
}

const ParametersForm: React.FC<ParametersFormProps> = ({parameters, onChange}) => {
    // Todo: data onChange

    return (
        <div>
            {Object.entries(parameters).map(([name, param]) => {
                return (
                <div key={name}>
                    <label>{param.description || name} </label>
                    <input
                        name={name}
                        className={styles.url_input}
                        type={param.type} // Consider dynamically setting the type based on param.type
                        value={param.default}
                        onChange={(e) => onChange(name, e.target.value)}
                        readOnly={param.read_only}
                    />
                </div>
                )
            })}
        </div>
    );
};

export default ParametersForm;
