import React, {useEffect, useMemo, useReducer, useRef, useState} from 'react';
import {NodeProps, Position, useUpdateNodeInternals} from 'reactflow';
import styles from './DefaultCustomNode.module.scss';
import {CustomNodeStatus, INodeData} from '../../interfaces/d';
import CustomHandle from "./CustomHandle";
import nodeManager, {NodeManagerClass} from "./NodeManager";
import {Tooltip} from 'react-tooltip';

const CustomNode: React.FC<NodeProps<INodeData>> = ({data}) => {
    const updateNodeInternals = useUpdateNodeInternals();

    const nodeRef = useRef<HTMLDivElement>(null);
    const [nodeHeight, setNodeHeight] = useState(0);
    const [nodeState, setNodeState] = useState<CustomNodeStatus>(data.status);

    let handleHeight = 30;
    let handleSpacing = 0;

    const handleCloseClick = () => {
        if (data.onClose) {
            data.onClose(data.id);
        }
    };

    useEffect(() => {
        const node = nodeRef.current;
        if (node) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    setNodeHeight(entry.contentRect.height);
                }
            });

            resizeObserver.observe(node);
            return () => resizeObserver.unobserve(node);
        }
    }, []);

    useEffect(() => {
        setNodeState(data.status);
        switch (data.status) {
            case CustomNodeStatus.PREPARING:
            case CustomNodeStatus.PROCESSING:
            case CustomNodeStatus.COMPLETED:
                if (nodeRef.current) {
                    nodeRef.current.className = `${styles.customNode} ${styles[data.status.toLowerCase()]}`;
                }
                break;
        }
    }, [data.status]);

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    useEffect(() => {
        if (nodeHeight > 0) {
            forceUpdate();
        }
    }, [nodeHeight]);

    let inputHandleCount = data.input?.names?.length || 0;
    let inputHandleTotalHeight = (inputHandleCount * handleHeight) + ((inputHandleCount - 1) * handleSpacing);
    let inputInitialTopOffset = nodeHeight > 0 ? (nodeHeight / 2) - (inputHandleTotalHeight / 2) : 0;
    inputInitialTopOffset += 18;

    let outputHandleCount = data.output?.names?.length || 0;
    let outputHandleTotalHeight = (outputHandleCount * handleHeight) + ((outputHandleCount - 1) * handleSpacing);
    let outputInitialTopOffset = nodeHeight > 0 ? (nodeHeight / 2) - (outputHandleTotalHeight / 2) : 0;
    outputInitialTopOffset += 18;

    const nodeClassName = `${styles.customNode} ${styles[nodeState]}`;
    const inputHandles = useMemo(() => data.input?.names?.map((inputName: string, index: number) => {
        return (
            <CustomHandle
                key={`input-${index}`}
                type="target"
                position={Position.Left}
                id={`input-${inputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={1}
                style={{
                    top: `${inputInitialTopOffset + ((handleHeight + handleSpacing) * index)}px`,
                    background: `${nodeManager.colorByHandleName(inputName)}`
                }}
                data-tooltip-id="DefaultNodeToolTip"
                data-tooltip-content={inputName}
            />
        )
    }), [data.input, nodeHeight, handleHeight, handleSpacing]);

    const outputHandles = useMemo(() => data.output?.names?.map((outputName: string, index: number) => {
        setTimeout(() => {
            updateNodeInternals(data.id);
        }, 0);

        return (
            <CustomHandle
                key={`output-${index}`}
                type="source"
                position={Position.Right}
                id={`output-${outputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={true}
                style={{
                    top: `${outputInitialTopOffset + ((handleHeight + handleSpacing) * index)}px`,
                    background: `${nodeManager.colorByHandleName(outputName)}`
                }}
                data-tooltip-id="DefaultNodeToolTip"
                data-tooltip-content={outputName}
            />
        )
    }), [data.output, nodeHeight, handleHeight, handleSpacing]);

    const nodeHeaderColor = () => {
        return NodeManagerClass.colorByNodeCategory(data.category)
    }

    return (
        <div className={`${nodeClassName}`} ref={nodeRef}>
            {nodeHeight && inputHandles}
            <div className={`${styles.node_header} customDragHandle`}
                 style={{cursor: 'grab', borderLeft: `4px solid ${nodeHeaderColor()}`}}>
                <div className={styles.title}>{data.label}</div>
                <div className={styles.close_node} onClick={handleCloseClick}>
                    <img alt="close" src="/images/aiflow/icon_node_close.svg"/>
                </div>
            </div>
            <div className={styles.node_content}>
                <textarea
                    className={styles.text_area}
                    value={data.parameter_values?.text || "No text available"}
                    readOnly={true}
                />
            </div>
            {nodeHeight && outputHandles}
            <Tooltip id="DefaultNodeToolTip" opacity="0.8"/>
            <div className={styles.node_footer}></div>
        </div>
    );
};

export default React.memo(CustomNode);
