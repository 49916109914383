import checkboxFalse from '../asset/checkbox_false.svg';
import checkboxTrue from '../asset/checkbox_true.svg';

import React, {CSSProperties} from 'react';

interface CustomCheckboxProps {
    checked: boolean;
    onChange: () => void;
}

const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, onChange }) => {
    const checkboxContainerStyle: CSSProperties = {
        display: 'inline-block',
        cursor: 'pointer',
    };

    const hiddenCheckboxStyle: CSSProperties = {
        display: 'none',
    };

    const styledCheckboxStyle: CSSProperties = {
        width: '32px',
        height: '32px',
        backgroundImage: `url(${checked ? checkboxTrue : checkboxFalse})`,
        backgroundSize: 'cover',
    };

    return (
        <div style={checkboxContainerStyle} onClick={onChange}>
            <input
                type="checkbox"
                style={hiddenCheckboxStyle}
                checked={checked}
                readOnly
            />
            <div style={styledCheckboxStyle}></div>
        </div>
    );
};

export default CustomCheckbox;
