import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './DragZoomSingle.module.scss';
import {DragZoomType} from "../interfaces/d";

export interface DragZoomSingleProps {
    ImageURL: string
}

const DragZoomSingle: FC<DragZoomSingleProps> = ({ImageURL}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const imageContainerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    const [DragZoomState, setDragZoomState] = useState<DragZoomType>({
        ImageURL: '',
        ContainerSize: {w: 0, h: 0},
        GlobalScale: 1,
        GlobalPos: {x: 0, y: 0},
        MaxScale: 10,
        FactorStep: 0.1
    });

    let container_size = {w: 0, h: 0};
    let zoom_target = {x: 0, y: 0};
    let zoom_point = {x: 0, y: 0};
    let curr_transform = containerRef.current ? containerRef.current.style.transition : '';
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePos, setLastMousePos] = useState({x: 0, y: 0});

    // init for scale slider
    useEffect(() => {
        if (!containerRef.current || !imageContainerRef.current) return;
        imageContainerRef.current.style.transition = 'transform 0s';
        containerRef.current.style.transition = 'transform 0s';

    }, []);

    // Mouse Wheel
    useEffect(() => {
        if (!containerRef.current || !imageContainerRef.current) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        container_size = {
            w: imageContainerRef.current.offsetWidth,
            h: imageContainerRef.current.offsetHeight
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        curr_transform = imageContainerRef.current.style.transition;
        imageContainerRef.current.style.transformOrigin = '0 0';

        const handleMouseWheel = (e: WheelEvent) => {
            e.preventDefault();
            if (!containerRef.current) return;

            const rect = containerRef.current.getBoundingClientRect();
            const delta = Math.max(-1, Math.min(1, -e.deltaY));
            const newGlobalScale = Math.max(0.05, Math.min(DragZoomState.MaxScale, DragZoomState.GlobalScale + delta * DragZoomState.FactorStep * DragZoomState.GlobalScale));
            const offset = {
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX
            };

            zoom_point.x = e.pageX - offset.left;
            zoom_point.y = e.pageY - offset.top;

            zoom_target.x = (zoom_point.x - DragZoomState.GlobalPos.x) / DragZoomState.GlobalScale;
            zoom_target.y = (zoom_point.y - DragZoomState.GlobalPos.y) / DragZoomState.GlobalScale;

            const newGlobalPos = {
                x: -zoom_target.x * newGlobalScale + zoom_point.x,
                y: -zoom_target.y * newGlobalScale + zoom_point.y
            };

            setDragZoomState((prevState) => ({
                ...prevState,
                GlobalPos: newGlobalPos,
                GlobalScale: newGlobalScale

            }));
        }

        if (containerRef.current) {
            containerRef.current.addEventListener('wheel', handleMouseWheel, {passive: false});

            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                containerRef.current?.removeEventListener('wheel', handleMouseWheel);
            };
        }
    }, [DragZoomState.GlobalScale, DragZoomState.GlobalPos]);

    // Update
    useEffect(() => {
        if (!imageContainerRef.current) return;
        imageContainerRef.current.style.transform = `translate( ${DragZoomState.GlobalPos.x}px, ${DragZoomState.GlobalPos.y}px) scale(${DragZoomState.GlobalScale}, ${DragZoomState.GlobalScale})`;
    }, [DragZoomState.GlobalScale, DragZoomState.GlobalPos]);

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            requestAnimationFrame(() => {
                const current_mouse_position = {
                    x: e.pageX,
                    y: e.pageY
                }

                const change_x = current_mouse_position.x - lastMousePos.x;
                const change_y = current_mouse_position.y - lastMousePos.y;

                setLastMousePos(current_mouse_position);
                const newGlobalPos = {
                    x: DragZoomState.GlobalPos.x + change_x,
                    y: DragZoomState.GlobalPos.y + change_y
                }

                setDragZoomState((prevState) => ({
                    ...prevState,
                    GlobalPos: newGlobalPos
                }));
            });
        }
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        if (imageContainerRef.current) {
            imageContainerRef.current.style.cursor = 'move';
            imageContainerRef.current.style.transition = 'transform 0s';
        }
        setLastMousePos({
            x: e.pageX,
            y: e.pageY
        });
    }

    const handleMouseUp = (e: React.MouseEvent) => {
        setIsDragging(false);
        if (!imageContainerRef.current) return;
        imageContainerRef.current.style.cursor = 'default';
        imageContainerRef.current.style.transition = curr_transform;
    }

    const centerImage = () => {
        if (!containerRef.current || !imageContainerRef.current || !imageRef.current) return;
        const containerWidth = containerRef.current.offsetWidth;
        const containerHeight = containerRef.current.offsetHeight;
        const imageWidth = imageRef.current.offsetWidth;
        const imageHeight = imageRef.current.offsetHeight;

        const newGlobalPos = {
            x: (containerWidth - imageWidth) / 2,
            y: (containerHeight - imageHeight) / 2
        };

        setDragZoomState((prevState) => ({
            ...prevState,
            GlobalPos: newGlobalPos
        }));
        setTimeout(() => {
            imageRef.current && (imageRef.current.style.opacity = '1')
        }, 0)
    };

    const handleImageLoad = () => {
        centerImage();
    };

    return (
        <div className={styles.dragzoom_container}
             ref={containerRef}
             onMouseDown={handleMouseDown}
             onMouseUp={handleMouseUp}
             onMouseOut={handleMouseUp}
             onMouseLeave={handleMouseUp}
             onMouseMove={handleMouseMove}
        >
            <div className={styles.dragzoom_image_container} ref={imageContainerRef}>
                <img src={ImageURL} alt='completed' className={styles.dragzoom_image} onLoad={handleImageLoad}
                     ref={imageRef}/>
            </div>
        </div>
    );
}

export default DragZoomSingle;
