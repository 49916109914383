import React, {useCallback, useEffect, useRef, useState} from 'react';
import {ReactFlowInstance} from 'reactflow';
import styles from './CustomControls.module.scss';

interface CustomControlsProps {
    reactFlowInstance: ReactFlowInstance;
    zoomLevel: number;
    setZoomLevel: (newZoomLevel: number) => void;
    onSaveFlow: (flowName: string) => void;
}

const CustomControls: React.FC<CustomControlsProps> = ({reactFlowInstance, zoomLevel, setZoomLevel, onSaveFlow}) => {
    const [showInput, setShowInput] = useState(false);
    const [flowName, setFlowName] = useState('');
    const inputRef = useRef<HTMLInputElement | null>(null);

    const updateViewport = useCallback(() => {
        setTimeout(() => {
            const {zoom} = reactFlowInstance.getViewport();
            setZoomLevel(zoom);
        }, 100);
    }, [reactFlowInstance, setZoomLevel]);

    const handleZoomIn = useCallback((e: React.MouseEvent) => {
        e.preventDefault();
        reactFlowInstance.zoomIn();
        updateViewport();
    }, [reactFlowInstance.getViewport().zoom]);

    const handleZoomOut = useCallback(() => {
        reactFlowInstance.zoomOut();
        updateViewport();
    }, [reactFlowInstance.getViewport().zoom]);

    const handleFitView = useCallback(() => {
        reactFlowInstance.fitView({padding: 0.1});
        updateViewport();
    }, [reactFlowInstance.getViewport().zoom]);

    const handleSaveClick = useCallback(() => {
        setShowInput(true);
    }, []);

    const handleKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
                onSaveFlow(flowName);
                setShowInput(false);
            } else if (e.key === 'Escape') {
                setFlowName('');
                setShowInput(false);
            }
        },
        [flowName, onSaveFlow]
    );

    const handleBlur = useCallback(() => {
        setFlowName('');
        setShowInput(false);
    }, []);

    useEffect(() => {
        if (showInput && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showInput]);

    return (
        <div className={styles.controls_container}>
            <div className={styles.controls_row}>
                <img src='/images/aiflow/icon_controls_zoom_out.svg' onClick={handleZoomOut}/>
                <div className={styles.zoom_value}>
                    {Math.round(zoomLevel * 100)}%
                </div>
                <img src='/images/aiflow/icon_controls_zoom_in.svg' onClick={handleZoomIn}/>
                <img src='/images/aiflow/icon_controls_zoom_fit.svg' onClick={handleFitView}/>
                <img src='/images/aiflow/icon_controls_lock_open.svg'/>
                <img src='/images/aiflow/icon_controls_save.svg' onClick={handleSaveClick}/>
            </div>

            {showInput && (
                <input
                    ref={inputRef}
                    type="text"
                    placeholder="Enter Flow Name"
                    value={flowName}
                    onChange={(e) => setFlowName(e.target.value)}
                    onKeyDown={handleKeyDown}
                    onBlur={handleBlur}
                    className={styles.input_box}
                />
            )}
        </div>
    );
};

export default CustomControls;
