import React, {useState} from 'react';
import {GenerativeAIPageStage} from "../interfaces/d";
import GenerativeAIModelList from "../components/GenerativeAIModelList";
import GenerativeAIModelProcess from "../components/GenerativeAIModelProcess";
import GenerativeAIModelResult from "../components/GenerativeAIModelResult";
import styles from './GenerativeAIPage.module.scss';

const GenerativeAIPage = () => {
    const [PageStage, setPageStage] = useState(GenerativeAIPageStage.MODELANDPROMPT);

    return (
        <main className={styles.main}>
            {PageStage === GenerativeAIPageStage.MODELANDPROMPT && <GenerativeAIModelList setPageStage={setPageStage}/>}
            {PageStage === GenerativeAIPageStage.PROCESSING && <GenerativeAIModelProcess setPageStage={setPageStage}/>}
            {PageStage === GenerativeAIPageStage.RESULT && <GenerativeAIModelResult setPageStage={setPageStage}/>}
        </main>
    )
}

export default GenerativeAIPage;