import React from 'react';
import styles from './DefaultCustomNode.module.scss';

interface RelationLinksProps {
    related_links: Record<string, string>;
}

const RelationLinks: React.FC<RelationLinksProps> = ({related_links}) => {
    const LinkIcons = new Map<string, string>();
    LinkIcons.set('huggingface', '/images/node-icon-huggingface.svg')
    LinkIcons.set('civitai', '/images/node-icon-civitai.png')
    LinkIcons.set('paper', '/images/node-icon-paper.svg')
    LinkIcons.set('github', '/images/node-icon-github.svg')

    return (
        <div className={styles.related_links}>
            {related_links && Object.entries(related_links).map(([key, value]) => (
                <div key={key}>
                    <a href={value} target="_blank" rel="noopener noreferrer"><img style={{width: "20px"}}
                                                                                   src={LinkIcons.get(key) ? LinkIcons.get(key) : '/images/no_image.svg'}
                                                                                   alt={key}/></a>
                </div>
            ))}
        </div>

    )
};

export default React.memo(RelationLinks);
