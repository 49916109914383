import React from 'react';
import styles from './ComingSoonPage.module.scss';

const ComingSoonPage = () => {
    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <img src='/images/coming_soon.svg'/>
                <span className={styles.coming_soon}>Coming Soon...</span>
            </div>
        </main>
    )
}

export default ComingSoonPage;