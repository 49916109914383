import React from 'react';
import styles from './GenerativeAIModelResult.module.scss';
import {GenerativeAIModelListProps} from "../interfaces/d";

const GenerativeAIModelResult: React.FC<GenerativeAIModelListProps> = ({setPageStage}) => {

    return (
        <div className={styles.wrapper}>
            <img src='/images/GenAI-Sample-Girl-Large.svg' alt='processed image'/>
            <button className={styles.download_button}>
                Download
            </button>
        </div>
    )
}

export default GenerativeAIModelResult;