import React, {useEffect, useState} from 'react';
import {fetchUserFavoriteModels} from "../services/userService";
import {UserFavorite, UserFavoriteModels} from "../interfaces/d";
import styles from "./DashboardFavoriteCards.module.scss";

const DashboardFavoriteCards = ({initialData}: { initialData: UserFavoriteModels }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [modelsPerPage, setModelsPerPage] = useState(6);
    const [totalModels, setTotalModels] = useState(initialData.total_models);
    const [totalPages, setTotalPages] = useState(initialData.total_page);
    const [favoriteCards, setFavoriteCards] = useState<UserFavorite[]>([]);
    const [isFirstAttach, setIsFirstAttach] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setFavoriteCards(initialData.favorite_models);
        setTotalPages(initialData.total_page);
        setTotalModels(initialData.total_models);
    }, [initialData]);

    useEffect(() => {
        const fetchModels = async () => {
            if (isLoading) return;
            setIsLoading(true);
            const data = await fetchUserFavoriteModels(currentPage);
            setFavoriteCards(data.data.favorites);
            setCurrentPage(data.data.page.current);
            setTotalPages(data.data.page.total);
            setTotalModels(data.data.total_count);
            setModelsPerPage(data.data.page.size);
            setIsLoading(false);
        };

        if (isFirstAttach) {
            setFavoriteCards(initialData.favorite_models);
            setTotalPages(initialData.total_page);
            setCurrentPage(initialData.current_page);
            setTotalModels(initialData.total_models);
            setIsFirstAttach(false);
        } else {
            fetchModels();
        }
    }, [currentPage]);

    const handlePageChange = (newPage: number) => {
        if (isLoading || currentPage === newPage) return;
        setCurrentPage(newPage);
    };

    const renderPagination = () => {
        let pages = [];
        const maxPageNumbers = 5;

        let startPage = Math.max(currentPage - Math.floor(maxPageNumbers / 2), 1);
        let endPage = Math.min(startPage + maxPageNumbers - 1, totalPages);

        if (totalPages > maxPageNumbers && endPage - startPage < maxPageNumbers - 1) {
            startPage = Math.max(endPage - (maxPageNumbers - 1), 1);
        }

        pages.push(
            <div
                key="prev"
                onClick={() => currentPage > 1 && setCurrentPage(currentPage - 1)}
                style={{cursor: 'pointer', visibility: currentPage > 1 ? 'visible' : 'hidden'}}
                className={styles.numbers}>
                <img src='/images/dashboard_icon_pagination_left.svg' alt='Previous'/>
            </div>
        );

        for (let i = startPage; i <= endPage; i++) {
            pages.push(
                <div
                    key={i}
                    onClick={() => handlePageChange(i)}
                    style={{cursor: 'pointer'}}
                    className={i === currentPage ? styles.selected : styles.numbers}>
                    {i}
                </div>
            );
        }
        pages.push(
            <div
                key="next"
                onClick={() => currentPage < totalPages && setCurrentPage(currentPage + 1)}
                style={{cursor: 'pointer', visibility: currentPage < totalPages ? 'visible' : 'hidden'}}
                className={styles.numbers}>
                <img src='/images/dashboard_icon_pagination_right.svg' alt='Next'/>
            </div>
        );
        return pages;
    };

    return (
        <div>
            <div className={styles.cards}>
                {favoriteCards && favoriteCards.map((card) => (
                    <div key={card.model_id} className={styles.card}>
                        <div className={styles.card_header}>
                            <div className={styles.model_name}>
                                {/*<img src={card.imageUrl} alt={card.model_id}/>*/}
                                {card.model_id}
                            </div>
                            <div className={styles.model_drag_handle}>
                                <img src='/images/dashboard_icon_draghandle.svg' alt='drag handle'/>
                            </div>
                        </div>
                        <div className={styles.card_body}>
                            {card.description ? card.description : 'none'}
                        </div>
                        <div className={styles.card_footer}>
                            <img src='/images/dashboard_icon_license.svg' alt='license'/>
                            License : {card.license ? card.license : 'unknown'}
                            <div style={{width: 10}}></div>
                            <img src='/images/dashboard_icon_calendar.svg' alt='calendar'/>
                            {
                                card.updated &&
                                new Date(card.updated * 1000).toLocaleDateString('en-CA', {
                                    month: '2-digit',
                                    day: '2-digit',
                                    year: '2-digit',
                                })
                            }
                        </div>
                    </div>
                ))}
            </div>
            <div className={styles.favorite_cards_pagination_wrapper}>
                <div className={styles.result}>
                    Showing {Math.min((currentPage - 1) * modelsPerPage + 1, totalModels)} to {Math.min(currentPage * modelsPerPage, totalModels)} of {totalModels} Results
                </div>
                <div className={styles.pagination}>
                    {totalPages && renderPagination()}
                </div>
            </div>
        </div>
    );
};

export default DashboardFavoriteCards;
