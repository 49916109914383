import React, {useEffect, useRef, useState} from 'react';
import styles from './LibraryModal.module.scss';
import {deleteFlow, loadFlowList, renameFlow, restoreFlow, trashFlow} from '../../services/flowService';
import {IAPIFlowListItem} from '../../interfaces/d';

interface ModalProps {
    isVisible: boolean;
    onClose: () => void;
    onFlowLoad: (flowId: string) => void;
    onFlowRename: (flowId: string, flowName: string) => void;
}

const LibraryModal: React.FC<ModalProps> = ({isVisible, onClose, onFlowLoad, onFlowRename}) => {
    const [flowListData, setFlowListData] = useState<IAPIFlowListItem[]>([]);
    const [activeTab, setActiveTab] = useState<'preset' | 'history' | 'delete'>('history');
    const [renamingFlowId, setRenamingFlowId] = useState<string | null>(null);
    const [newFlowName, setNewFlowName] = useState<string>('');
    const [selectedFlowId, setSelectedFlowId] = useState<string | null>(null);
    const [menuPosition, setMenuPosition] = useState<{ top: number; left: number } | null>(null);
    const modalRef = useRef<HTMLDivElement | null>(null);
    const flowContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const loadData = async () => {
            const data: IAPIFlowListItem[] | undefined = await loadFlowList();
            if (data) {
                setFlowListData(data);
            }
        };

        if (isVisible) {
            loadData();
        }
    }, [isVisible]);

    useEffect(() => {
        const handleScroll = () => {
            setMenuPosition(null);
            setSelectedFlowId(null);
        };

        const flowContainer = flowContainerRef.current;

        if (flowContainer) {
            flowContainer.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (flowContainer) {
                flowContainer.removeEventListener('scroll', handleScroll);
            }
        };
    }, []);

    const onSelectFlowHandler = (flow_id: string) => () => {
        onFlowLoad(flow_id);
    };

    const handleTabClick = (tab: 'preset' | 'history' | 'delete') => () => {
        setActiveTab(tab);
    };

    const onRenameClick = (e: React.MouseEvent, flowId: string, currentName: string) => {
        e.stopPropagation();
        setRenamingFlowId(flowId);
        setNewFlowName(currentName);

        onFlowRename(flowId, currentName);
    };

    const onRenameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setNewFlowName(e.target.value);
    };

    const onRenameSubmit = async (e: React.FormEvent, flowId: string) => {
        e.preventDefault();

        try {
            await renameFlow(flowId, newFlowName);
        } catch (e) {
            console.error(e);
        }

        const updatedFlows = flowListData.map(flow =>
            flow.id === flowId ? {...flow, name: newFlowName} : flow
        );
        setFlowListData(updatedFlows);
        setRenamingFlowId(null);
    };

    const onTrashClick = async (e: React.MouseEvent, flowId: string) => {
        e.stopPropagation();
        if (!flowId) return;

        await trashFlow(flowId);
        const updatedFlows = flowListData.map(flow =>
            flow.id === flowId ? {...flow, status: 'trashed'} : flow
        );
        setFlowListData(updatedFlows);
    };

    const filteredFlowList = flowListData.filter(flow => {
        switch (activeTab) {
            case 'preset':
                return flow.status === 'preset';
            case 'history':
                return flow.status === 'activated';
            case 'delete':
                return flow.status === 'trashed';
            default:
                return true;
        }
    });

    const formatDate = (timestamp: number) => {
        const date = new Date(timestamp * 1000);
        const now = new Date();
        const timeDiff = now.getTime() - date.getTime();
        const dayDiff = Math.floor(timeDiff / (1000 * 3600 * 24));
        const monthDiff = now.getMonth() - date.getMonth() + (12 * (now.getFullYear() - date.getFullYear()));
        const yearDiff = now.getFullYear() - date.getFullYear();

        if (dayDiff < 7) {
            return date.toLocaleString('en-US', {
                month: 'short',
                day: 'numeric',
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            });
        } else if (dayDiff < 30) {
            return `${dayDiff} days ago`;
        } else if (yearDiff < 1) {
            return `${monthDiff} months ago`;
        } else {
            return `${yearDiff} years ago`;
        }
    };

    const handleMoreClick = (e: React.MouseEvent, flowId: string) => {
        e.stopPropagation();
        if (!modalRef.current) return;

        const rect = (e.currentTarget as HTMLElement).getBoundingClientRect();
        const left = rect.left - modalRef.current.getBoundingClientRect().left;
        const top = rect.top - modalRef.current.getBoundingClientRect().top + 30;

        // first menu best positon: top 250, left: 550
        // second menu best position: top 410 left: 550
        setMenuPosition({
            top: top,
            left: left,
        });

        setSelectedFlowId(flowId === selectedFlowId ? null : flowId);
    };

    if (!isVisible) return null;

    return (
        <div className={styles.modal_backdrop} onClick={() => onClose()}>
            <div className={styles.library_modal} ref={modalRef} onClick={e => e.stopPropagation()}>
                <button onClick={() => onClose()} className={styles.close_button}>&times;</button>
                <div className={styles.modal_title}>
                    AI Flow Libraries
                </div>
                <div className={styles.modal_tab_container}>
                    <div
                        className={`${styles.modal_tab} ${activeTab === 'preset' ? styles.active : ''}`}
                        onClick={handleTabClick('preset')}
                    >
                        <img
                            src={`/images/aiflow/icon_library_preset${activeTab === 'preset' ? '_on' : ''}.svg`}
                            alt="Preset Tab"
                        />
                        Preset
                    </div>
                    <div
                        className={`${styles.modal_tab} ${activeTab === 'history' ? styles.active : ''}`}
                        onClick={handleTabClick('history')}
                    >
                        <img
                            src={`/images/aiflow/icon_library_history${activeTab === 'history' ? '_on' : ''}.svg`}
                            alt="History Tab"
                        />
                        History
                    </div>
                    <div
                        className={`${styles.modal_tab} ${activeTab === 'delete' ? styles.active : ''}`}
                        onClick={handleTabClick('delete')}
                    >
                        <img
                            src={`/images/aiflow/icon_library_delete${activeTab === 'delete' ? '_on' : ''}.svg`}
                            alt="Delete Tab"
                        />
                        Delete
                    </div>
                </div>

                <div className={styles.search_container}>
                    <img src="/images/aiflow/icon_library_search.svg" alt="Search"/>
                    <input className={styles.search_input} placeholder="Search"/>
                </div>
                <div className={styles.flow_container} ref={flowContainerRef}>
                    {filteredFlowList.map(flow => (
                        <div
                            key={flow.id}
                            className={styles.flow_item_container}
                            onClick={onSelectFlowHandler(flow.id)}

                        >
                            <img className={styles.flow_image} src="/images/dashboard_sample_girl.svg" alt="Flow"/>
                            <div className={styles.flow_info_container}>
                                {renamingFlowId === flow.id ? (
                                    <form onSubmit={(e) => onRenameSubmit(e, flow.id)}>
                                        <input
                                            type="text"
                                            value={newFlowName}
                                            onChange={onRenameChange}
                                            className={styles.rename_input}
                                            onClick={(e) => e.stopPropagation()}
                                            autoFocus
                                            placeholder={flow.name}
                                        />
                                    </form>
                                ) : (
                                    <div className={styles.flow_name}>{flow.name ? flow.name : 'Untitled'}</div>
                                )}
                                <div className={styles.flow_info}>
                                    {flow.category_count} categories, {flow.model_count} Models
                                </div>
                                <div className={styles.flow_created}>
                                    {formatDate(flow.created)}
                                </div>
                                {activeTab === 'history' && (
                                    <div className={styles.icon_container}>
                                        <img
                                            className={styles.rename_icon}
                                            src="/images/aiflow/icon_library_rename.svg"
                                            alt="Rename"
                                            onClick={(e) => onRenameClick(e, flow.id, flow.name)}
                                        />
                                        <img
                                            className={styles.delete_icon}
                                            src="/images/aiflow/icon_library_trash.svg"
                                            alt="Delete"
                                            onClick={(e) => onTrashClick(e, flow.id)}
                                        />
                                    </div>
                                )}

                                {activeTab === 'delete' && (
                                    <div className={styles.icon_container}>
                                        <img
                                            className={styles.more_icon}
                                            src="/images/aiflow/icon_library_delete_more.svg"
                                            alt="More Options"
                                            onClick={(e) => handleMoreClick(e, flow.id)}
                                        />
                                        {selectedFlowId === flow.id && menuPosition && (
                                            <div
                                                className={styles.more_menu}
                                                style={{top: `${menuPosition.top}px`, left: `${menuPosition.left}px`}}
                                            >
                                                <div
                                                    className={styles.more_menu_item}
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        // onClose();
                                                        await restoreFlow(flow.id)
                                                        // setFlowListData(prevFlows => prevFlows.filter(f => f.id !== flow.id));
                                                        const updatedFlows = flowListData.map(_flow =>
                                                            _flow.id === flow.id ? {
                                                                ..._flow,
                                                                status: 'activated'
                                                            } : _flow
                                                        );
                                                        setFlowListData(updatedFlows);
                                                    }}
                                                >
                                                    Restore
                                                </div>
                                                <div
                                                    className={styles.more_menu_item}
                                                    onClick={async (e) => {
                                                        e.stopPropagation();
                                                        await deleteFlow(flow.id);
                                                        setFlowListData(prevFlows => prevFlows.filter(f => f.id !== flow.id));
                                                    }}
                                                >
                                                    Permanently Delete
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                )}

                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LibraryModal;
