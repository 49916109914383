import React, {useEffect, useRef, useState} from 'react';
import {UploadModalStage} from "../interfaces/d";
import ApiClient from "../services/apiClient";
import styles from "./ImageUpload.module.scss";
import {API_URLS} from "../config/defaultConfig";

const ImageUpload: React.FC<{
    setModalStage: (stage: UploadModalStage) => void,
    selectedModelIds: Record<string, boolean>,
    setTransactionId: (id: string) => void
}> = ({ setModalStage, selectedModelIds, setTransactionId }) => {
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const fileInputRef = useRef<HTMLInputElement>(null);

    const showDropzone = !selectedFile;
    const showUploadProgress = selectedFile;

    const uploadFile = async () => {
        if (!selectedFile) return;

        const formData = new FormData();
        formData.append('file', selectedFile);

        Object.keys(selectedModelIds).forEach(key => {
            formData.append('models', key);
        });

        try {
            const response = await ApiClient.post(API_URLS.SISRModelProcess, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: progressEvent => {
                    if (progressEvent.total) {
                        let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        if (percentCompleted >= 100) {
                            percentCompleted = 99;
                        }
                        setUploadPercentage(percentCompleted);
                    }
                }
            });

            const transactionId = response.data.data.transaction_id;
            setTransactionId(transactionId);
            setUploadPercentage(100);
            setModalStage(UploadModalStage.GENERATE_PROCESS);
        } catch (error) {
            console.error('Upload error:', error);
        }
    };

    useEffect(() => {
        uploadFile();
    }, [selectedFile]);

    const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
        e.preventDefault();
    };

    const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
        try {
            e.preventDefault();
            if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
                const file = e.dataTransfer.files[0];
                setSelectedFile(file);
            }
        } catch (er) {
            console.log(er);
        }
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.files && e.target.files.length > 0) {
            const file = e.target.files[0];
            setSelectedFile(file);
        }
    };

    const handleCloseClick = () => {
        setModalStage(UploadModalStage.OFF);
    }

    return (
        <div className={styles.main}>
            <div className={styles.close_button}>
                <img src='/images/upload_box_close_button.svg' alt='close' onClick={handleCloseClick} />
            </div>
            <div className={styles.current_step}>
                <div className={styles.active_step}>Upload Image</div>
                <img src='/images/caret_right.svg' alt='Caret right' />
                <div>Processing</div>
            </div>

            <div className={styles.upload_desc_container}>
                <div className={styles.upload_desc}>
                    Upload New Image
                </div>
                <div className={styles.upload_sub_desc}>
                    We support PNG, JPEG files
                </div>
            </div>

            {showDropzone && (
                <div className={styles.dropzone} onClick={handleButtonClick} onDragOver={handleDragOver} onDrop={handleDrop}>
                    <div className={styles.upload_image}>
                        <img src='/images/upload_file.svg' alt='file' />
                    </div>
                    <div>
                        <div className={styles.upload_image_desc}>Click to upload or drag and drop</div>
                        <div className={styles.upload_image_desc_size}>Maximum image size 2048*2048 px</div>
                    </div>

                </div>
            )}
            {showUploadProgress && (
                <div className={styles.progress_container}>
                    <div className={styles.filename_and_percentage_container}>
                        <div className={styles.uploading_filename}>{selectedFile.name}</div>
                        <div className={styles.uploading_percentage}>{uploadPercentage}% <img src='/images/stop_image_upload_button.svg' alt='Stop' /></div>
                    </div>
                    <progress value={uploadPercentage} max="100"></progress>
                </div>
            )}

            <input type="file" onChange={handleChange} accept="image/png, image/jpeg" style={{ display: 'none' }}
                   ref={fileInputRef} />

        </div>
    );
}

export default React.memo(ImageUpload);
