import {Connection, Edge, Node} from "reactflow";
import {CustomNodeStatus, CustomNodeType, IFlowNodeSource, INodeDataUI} from "../../interfaces/d";
import {v4 as uuidv4} from "uuid";

export class NodeManagerClass {
    nodesMap = new Map();
    edgesMap = new Map();

    constructor(nodes: Node[] = [], edges: Edge[] = []) {
        this.initializeNodes(nodes);
        this.initializeEdges(edges);
    }

    reset() {
        this.nodesMap = new Map();
        this.edgesMap = new Map();
    }

    initializeNodes(nodes: Node[]) {
        nodes.forEach(node => {
            this.nodesMap.set(node.id, node);
        });
    }

    initializeEdges(edges: Edge[]) {
        edges.forEach(edge => {
            this.edgesMap.set(edge.id, edge);
        });
    }

    addNode(node: Node) {
        this.nodesMap.set(node.id, node);
    }

    getNode(node_id: string) {
        return this.nodesMap.get(node_id);
    }

    removeNodes(deletedNodes: Node[]) {
        deletedNodes.forEach((node: Node) => {
            this.updateDescendantsState(node.id, CustomNodeStatus.PREPARING);
            this.removeNode(node.id);
        })
    }

    removeNode(nodeId: string) {
        this.nodesMap.delete(nodeId);
        this.edgesMap.forEach((edge, edgeId) => {
            if (edge.source === nodeId || edge.target === nodeId) {
                this.edgesMap.delete(edgeId);
            }
        });
        this.updateDescendantsState(nodeId, CustomNodeStatus.PREPARING);
    }

    addEdge(edge: Edge | Connection) {
        this.edgesMap.set(`${edge.sourceHandle}_${edge.targetHandle}`, edge);
    }

    removeEdges(deletedEdges: Edge[]) {
        deletedEdges.forEach((edge: Edge) => {
            if (edge.target) {
                this.updateDescendantsState(edge.target, CustomNodeStatus.PREPARING);
            }
            this.removeEdge(edge);
        })
    }

    removeEdge(edge: Edge) {
        const source_node = this.getNode(edge.source);
        if (source_node) {
            const new_data = {
                ...source_node.data,
            }
            if (new_data.output_ids) {
                new_data.output_ids = source_node.data.output_ids.filter((id: string) => id !== edge.target);
            }
            if (new_data.ui && new_data.ui.connection) {
                new_data.ui.connection = new_data.ui.connection.filter((connection: any) => {
                    return !(connection.source === edge.source && connection.target === edge.target);
                });
            }
            this.updateNodeData(source_node, new_data);
        }

        const target_node = this.getNode(edge.target);
        if (target_node) {
            const new_data = {
                ...target_node.data
            }
            if (new_data.input_ids) {
                new_data.input_ids = target_node.data.input_ids.filter((id: string) => id !== edge.source);
            }
            if (new_data.ui && new_data.ui.connection) {
                new_data.ui.connection = new_data.ui.connection.filter((connection: any) => {
                    return !(connection.source === edge.source && connection.target === edge.target);
                });
            }
            this.updateNodeData(target_node, new_data);
        }
        this.edgesMap.delete(`${edge.sourceHandle}_${edge.targetHandle}`);
    }

    getNodes() {
        return Array.from(this.nodesMap.values());
    }

    getEdges() {
        return Array.from(this.edgesMap.values());
    }

    getChildNodes = (node: Node) => {
        const nodes = this.getNodes();
        const out_edges = this.getEdges().filter(edge => edge.source === node.id);
        const child_nodes = out_edges.map(edge => nodes.find(node => node.id === edge.target));
        return child_nodes.filter(node => node !== undefined) || [];
    }

    getParentNodes = (node: Node) => {
        const nodes = this.getNodes();
        const out_edges = this.getEdges().filter(edge => edge.target === node.id);
        const parent_nodes = out_edges.map(edge => nodes.find(node => node.id === edge.source));
        return parent_nodes.filter(node => node !== undefined) || [];
    }

    updateNodeData = (node: Node, data: any): Node => {
        const updatedNode = {
            ...node,
            data: {
                ...node.data,
                ...data,
                status: data.status, // Ensure status is explicitly updated
            },
        };
        this.nodesMap.set(updatedNode.id, updatedNode);
        // console.log('Updated node data:', updatedNode.data);
        return updatedNode;
    };

    updateEdgeAnimation = (edge: Edge, node: Node, prevNodes: Node[], data: any): Edge => {
        if (edge.source === node.id) {
            const targetNodeIndex = prevNodes.findIndex(n => n.id === edge.target);
            if (targetNodeIndex !== -1) {
                prevNodes[targetNodeIndex] = {
                    ...prevNodes[targetNodeIndex],
                    data: {
                        ...prevNodes[targetNodeIndex].data,
                        node_state: CustomNodeStatus.PROCESSING,
                        image_preview_url: data.image_preview_url,
                    },
                };
            }
            return {...edge, animated: false};
        }
        return edge;
    };

    updateTargetNodeState = (prevNodes: Node[], prevEdges: Edge[], data: any): Edge[] => {
        const updatedEdges = prevEdges.map(edge =>
            this.updateEdgeAnimation(edge, data, prevNodes, data)
        );
        return updatedEdges;
    };

    findAllReachableNodes = (node_id: string, visited = new Set()) => {
        visited.add(node_id);
        this.getEdges().forEach(edge => {
            if (edge.source === node_id && !visited.has(edge.target)) {
                this.findAllReachableNodes(edge.target, visited);
            }
        });
        return visited;
    }

    // Edge validation before edge connect
    isValidConnection = (connection: Connection) => {
        const extractType = (handle_id: string) => {
            // console.log(`handle_id: ${handle_id}`);
            if (!handle_id) return '';
            const parts = handle_id.split('-');
            if (parts.length >= 3) {
                return parts.slice(0, 2).join('-'); // 'input-image' return
            }
            return "";
        };
        // console.log(`isValidConnection`, connection)

        const nodes = this.getNodes();
        const edges = this.getEdges();

        const targetNode = nodes.find(node => node.id === connection.target);
        const sourceNode = nodes.find(node => node.id === connection.source);

        if (!targetNode || !sourceNode) {
            console.error("Source or target node does not exist.");
            return false;
        }

        const hasCycle = (node: Node, visited = new Set(), origin: Node) => {
            if (visited.has(node.id)) return false;
            visited.add(node.id);

            const outgoers = edges
                .filter(edge => edge.source === node.id && !visited.has(edge.target))
                .map(edge => nodes.find(n => n.id === edge.target));

            for (const outgoer of outgoers) {
                if (outgoer.id === origin.id || hasCycle(outgoer, visited, origin)) {
                    return true;
                }
            }
            return false;
        };

        if (targetNode.id === connection.source || hasCycle(targetNode, new Set(), sourceNode)) {
            return false;
        }

        const AllowConnectType: { [key: string]: string[] } = {
            "output-mask": ["input-mask"],
            "output-scale": ["input-scale"],
            "output-image": ["input-image"],
            "output-text": ["input-text"],
            "output-realnumber": ["input-realnumber"],
        };

        if (!connection.sourceHandle || !connection.targetHandle) return false;

        const source_handle = extractType(connection.sourceHandle.toLowerCase());
        const target_handle = extractType(connection.targetHandle.toLowerCase());
        // console.log(`isValidConnection source handle id: ${source_handle}, target handle id: ${target_handle}`);

        if (source_handle && target_handle) {
            const validTargets = AllowConnectType[source_handle];
            return validTargets && validTargets.includes(target_handle);
        }

        return false;
    };

    createNodeData = (model_name: string, node_type: CustomNodeType, position: INodeDataUI, model_data: IFlowNodeSource, onCustomNodeChangeHandler: any, onCustomNodeCloseHandler: any) => {
        const new_node_id = uuidv4();
        let parameter_values: Record<string, any> = {};
        Object.entries(model_data.parameters).forEach(([key, value]) => {
            parameter_values[key] = value.default;
        })

        const create_values = {
            id: new_node_id,
            type: node_type,
            position,
            dragHandle: '.customDragHandle',
            data: {
                ...model_data,
                id: new_node_id,
                created: Date.now(),
                label: model_name,
                parameter_values: parameter_values,
                status: CustomNodeStatus.PREPARING,
                type: node_type,
                ui: position,
                onChange: onCustomNodeChangeHandler,
                onClose: onCustomNodeCloseHandler,
                isConnectable: true
            }
        }
        // console.log(create_values);
        return create_values;
    };

    static colorByNodeCategory(category: string = ''): string {
        let color = '#fff';
        switch (category.toLowerCase()) {
            case "deblur":
                color = '#F2EB0F';
                break;
            case "denoise":
                color = '#47FFB9';
                break;
            case "txt2img":  // GenAI : Text  to Image
                color = '#FF6161';
                break;
            case "img txt2img":  // GenAI : Image+Text to Image
                color = '#BA75FF';
                break;
            case "txt2txt":  // LM/LLM : Text to Text
                color = '#FF4DCF';
                break;
            case "img2txt":  // Captioning :  Image to Text
                color = '#5294FF';
                break;
            case "vqa img txt2img":  // VQA : Image+Text to Image
                color = '#5294FF';
                break;
            case "voice2txt":  // Voice to Text
                color = '#00B5B8';
                break;
            case "txt2voice":  // Text  to Voice
                color = '#F6C927';
                break;
            case "inpainting":  // Inpainting
                color = '#00C077';
                break;
            case "classification":  // Image Classification
                color = '#00BB1E';
                break;
            case "object detection":  // Image Object Detection
                color = '#7DA2FF';
                break;
            case "image segmentation":  // Image Segmentation
                color = '#FFA3E7';
                break;
            case "sisr":  // SISR Any Res
                color = '#6D51FF';
                break;
            case "sisr":  // Super Resolution
                color = '#84C391';
                break;
        }
        return color
    }

    colorByHandleName(handle_name: string): string {
        switch (handle_name.toLowerCase()) {
            case 'text':
                return '#fbbf47';
            case 'float':
                return '#684e1a';
            case 'image':
                return '#184ee6';
            case 'mask':
                return '#a32400';
            case 'scale':
                return '#4eab24';
            case 'txt2img input':
                return '#80edff';
            default:
                return '#888';
        }
    }

    colorByHandleID(handle_id: string): string {
        const regex = /^(input|output)\-([a-zA-Z]+)\-/;
        const match = handle_id.match(regex);
        if (!match) {
            return '#888';
        }
        const handle_name = match[2];
        return this.colorByHandleName(handle_name);
    }

    updateDescendantsState = (nodeId: string, newStatus: CustomNodeStatus) => {
        console.log(`updateDescendantsState called: ${nodeId} childs status to ${newStatus}`);

        const updateNodes = (nId: string, visited: Set<string> = new Set()) => {
            visited.add(nId);
            const targetEdges = this.getEdges().filter(edge => edge.source === nId);

            console.log(`node ${nId}, target edges:`, targetEdges);

            targetEdges.forEach(edge => {
                console.log(`edge: ${edge.id}`)
                if (!visited.has(edge.target)) {
                    const targetNode = this.getNode(edge.target);
                    if (targetNode) {
                        this.updateNodeData(targetNode, {status: newStatus});
                        updateNodes(edge.target, visited);
                    } else {
                        console.log(`target node not found: ${edge.target}`);
                    }
                }
            });
        };

        const startingNode = this.getNode(nodeId);
        if (startingNode) {
            console.log(`node found: ${nodeId}`);

            this.updateNodeData(startingNode, {status: newStatus});
            updateNodes(nodeId);
        } else {
            console.log(`node not found: ${nodeId}`);
        }
    };

    requirePolling() {
        const nodes = this.getNodes();
        if (nodes.length < 2) {
            return false;
        }

        // All nodes Completed
        if (nodes.every(node => node.data.status === CustomNodeStatus.COMPLETED)) {
            return false;
        }

        // All nodes Preparing
        if (nodes.every(node => node.data.status === CustomNodeStatus.PREPARING)) {
            return false;
        }

        // Polling needed if any AI-Model node is processing
        if (nodes.some(node => node.data.status === CustomNodeStatus.PROCESSING && node.data.type === CustomNodeType.AI_MODEL)) {
            return true;
        }

        // Check if non-input nodes are preparing
        for (const node of this.getNodes()) {
            if (node.data.status !== CustomNodeStatus.COMPLETED && node.data.category !== 'input') {
                const parent_nodes = this.getParentNodes(node)

                // No need to poll if there are no parent nodes
                if (parent_nodes.length === 0) {
                    continue;
                }

                // Polling needed if all parent nodes are completed
                const input_count = node.data.input.names.length;
                if (input_count !== parent_nodes.length) {
                    continue
                }

                if (parent_nodes.every(parent_node => parent_node.data.status === CustomNodeStatus.COMPLETED)) {
                    return true;
                }
            }
        }
        return false;
    }
}

const NodeManager = new NodeManagerClass();
export default NodeManager;
