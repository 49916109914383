import React from 'react';
import styles from './CategorySelector.module.scss';
import {CategorySelectorProps} from "../interfaces/d";
import {useNavigate} from "react-router-dom";

const images = {
    modelIconDS: require('../asset/icon_model_ds.svg').default,
    modelIconGR: require('../asset/icon_model_gr.svg').default,
    modelIconIP: require('../asset/icon_model_ip.svg').default,
    modelIconRC: require('../asset/icon_model_rc.svg').default,
    modelIconSR: require('../asset/icon_model_sr.svg').default,
    modelIconST: require('../asset/icon_model_st.svg').default,
    modelIconSelectedEffect: require('../asset/icon_model_selected_effect.svg').default
};

const CategorySelector: React.FC<CategorySelectorProps> = ({ selectedMainCategory,
                                                               onSelectMainCategory,
                                                               categories}) => {
    const navigate = useNavigate();
    return (
        <div className={styles.categories}>
            {Object.keys(categories).map(categoryName => (
                <div
                    key={categoryName}
                    className={`${styles.category_icon_wrapper} ${selectedMainCategory === categoryName ? styles.selected : ''}`}
                    onMouseDown={() => onSelectMainCategory(categoryName)}>
                    <div className={styles.background_icon}
                         style={{'--hover-background-image': `url(${images.modelIconSelectedEffect})`}}/>
                    <img className={styles.model_icon} src={categories[categoryName].icon_image} alt={categoryName}/>
                </div>
            ))}

            {/*{selectedMainCategory && mainCategories[selectedMainCategory].subCategories?.map((subCategory: { name: string; image: string }) => (*/}
            {/*    <div key={subCategory.name} className={styles.subCategory_icon_wrapper} onClick={() => handleSubCategorySelect(subCategory.name)}>*/}
            {/*        <img className={styles.subCategory_icon} src={subCategory.image} alt={subCategory.name} />*/}
            {/*    </div>*/}
            {/*))}*/}

        </div>
    );
};

export default CategorySelector;
