import React, {createContext, useContext, useEffect, useState} from 'react';
import {AuthContextType, AuthProviderProps, SignInResponse, User} from '../interfaces/d'
import {API_URLS} from "../config/defaultConfig";
import ApiClient from "./apiClient";


const initialAuthState: AuthContextType = {
    user: null,
    signIn: () => {
    },
    signOut: () => {
    },
    isLoggedIn: false,
    loading: true,
};

const AuthContext = createContext<AuthContextType>(initialAuthState);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState(true);
    const [isLoggedIn, setIsLoggedIn] = useState(false);

    useEffect(() => {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            const userData = JSON.parse(storedUser);
            setUser(userData);
        }
        setLoading(false);
    }, []);

    useEffect(() => {
        if (user === null) {
            setIsLoggedIn(false);
        } else {
            setIsLoggedIn(true);
        }
    }, [user]);

    const signIn = (userData: User) => {
        setUser(userData);
        sessionStorage.setItem('user', JSON.stringify(userData));
        if (userData.session_id) {
            sessionStorage.setItem('session_id', userData.session_id);
        }
    };

    const signOut = () => {
        try {
            APISignOut().then(data => {
                sessionStorage.removeItem('user');
                sessionStorage.removeItem('session_id');
                setUser(null);
            });
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <AuthContext.Provider value={{user, signIn, signOut, loading, isLoggedIn}}>
            {children}
        </AuthContext.Provider>
    );
};

export const APISupremumSignInUsingGoogleCredential = async (credential: object): Promise<SignInResponse> => {
    const response = await ApiClient.post(API_URLS.GoogleSignIn, credential);
    if (response.status !== 200) {
        throw new Error('Google Sign-In failed');
    }
    return response.data;
};

export const APISignOut = async () => {
    const response = await ApiClient.get(API_URLS.SignOut);
    if (response.status !== 200) {
        throw new Error('Sign-Out failed');
    }

    return response.data;
};
