import ApiClient from './apiClient';
import {
    Category,
    CategoryMap,
    GenAIModels,
    IAPIResponse,
    IGetModelAPI,
    IModelModel,
    ModelsAndCategories
} from '../interfaces/d';
import {API_URLS, CATEGORY_ICON_IMAGE} from "../config/defaultConfig";


export const fetchModelsAndCategories = async ():Promise<ModelsAndCategories> => {
    try {
        const response = await ApiClient.get(API_URLS.Models);
        const data: IAPIResponse<IGetModelAPI> = response.data;

        const models = data.data.models;
        const categories = transformCategories(data.data.category_map);

        return { models, categories };
    } catch (error) {
        console.error('Error fetching models and categories:', error);
        return { models: [], categories: {} };
    }
};

export const fetchGenAIModels = async (): Promise<GenAIModels> => {
    try {
        const response = await ApiClient.get(API_URLS.Models);
        const models: IModelModel[] = response.data.data.models;

        const filteredModels = models.filter(model => model.category && model.category.main === 'GenAI-Txt2Img');
        return {filteredModels};

    } catch (error) {
        console.error('Error fetching filtered models:', error);
        return {filteredModels: []};
    }
};

const transformCategories = (categories: CategoryMap): { [category: string]: Category } => {
    const transformed: { [category: string]: Category } = {};
    Object.keys(categories).forEach((categoryName) => {
        if (categoryName in CATEGORY_ICON_IMAGE) {
            transformed[categoryName] = {
                icon_image: CATEGORY_ICON_IMAGE[categoryName],
                include_string_from_model_metadata_name: categoryName
            };
        }
    });

    return transformed;
};
