import React, {FC, useEffect, useRef, useState} from 'react';
import styles from './DragZoom.module.scss';
import {DragZoomProps} from "../interfaces/d";

const DragZoom: FC<DragZoomProps> = ({
                                         ImageURL, ContainerSize, GlobalScale,
                                         GlobalPos, MaxScale, FactorStep, onStateChange
                                     }) => {

    const containerRef = useRef<HTMLDivElement>(null);
    const slideRef = useRef<HTMLDivElement>(null);

    let container_size = {w: 0, h: 0};
    let zoom_target = {x: 0, y: 0};
    let zoom_point = {x: 0, y: 0};
    let curr_transform = containerRef.current ? containerRef.current.style.transition : '';
    const [isDragging, setIsDragging] = useState(false);
    const [lastMousePos, setLastMousePos] = useState({x: 0, y: 0});

    // init for scale slider
    useEffect(() => {
        if (!containerRef.current || !slideRef.current) return;
        slideRef.current.style.transition = 'transform 0s';
        containerRef.current.style.transition = 'transform 0s';

        onStateChange({
            ImageURL,
            ContainerSize: {
                w: slideRef.current.offsetWidth,
                h: slideRef.current.offsetHeight
            },
            GlobalScale,
            GlobalPos,
            MaxScale,
            FactorStep
        });
    }, []);

    // Mouse Wheel
    useEffect(() => {
        if (!containerRef.current || !slideRef.current) return;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        container_size = {
            w: slideRef.current.offsetWidth,
            h: slideRef.current.offsetHeight
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
        curr_transform = slideRef.current.style.transition;
        slideRef.current.style.transformOrigin = '0 0';

        const handleMouseWheel = (e: WheelEvent) => {
            e.preventDefault();
            if (!containerRef.current) return;

            const rect = containerRef.current.getBoundingClientRect();
            const delta = Math.max(-1, Math.min(1, -e.deltaY));
            const newGlobalScale = Math.max(1, Math.min(MaxScale, GlobalScale + delta * FactorStep * GlobalScale));
            const offset = {
                top: rect.top + window.scrollY,
                left: rect.left + window.scrollX
            };

            zoom_point.x = e.pageX - offset.left;
            zoom_point.y = e.pageY - offset.top;

            zoom_target.x = (zoom_point.x - GlobalPos.x) / GlobalScale;
            zoom_target.y = (zoom_point.y - GlobalPos.y) / GlobalScale;

            const newGlobalPos = {
                x: -zoom_target.x * newGlobalScale + zoom_point.x,
                y: -zoom_target.y * newGlobalScale + zoom_point.y
            };

            onStateChange({
                ImageURL,
                ContainerSize: container_size,
                GlobalScale: newGlobalScale,
                GlobalPos: newGlobalPos,
                MaxScale,
                FactorStep
            });
        }

        if (containerRef.current) {
            containerRef.current.addEventListener('wheel', handleMouseWheel, {passive: false});

            return () => {
                // eslint-disable-next-line react-hooks/exhaustive-deps
                containerRef.current?.removeEventListener('wheel', handleMouseWheel);
            };
        }
    }, [GlobalScale, GlobalPos]);

    // Update
    useEffect(() => {
        if (!slideRef.current) return;
        slideRef.current.style.transform = `translate( ${GlobalPos.x}px, ${GlobalPos.y}px) scale(${GlobalScale}, ${GlobalScale})`;
    }, [GlobalScale, GlobalPos]);

    const handleMouseMove = (e: React.MouseEvent) => {
        if (isDragging) {
            // console.log(GlobalPos, e.pageX, e.pageY);
            requestAnimationFrame(() => {
                const current_mouse_position = {
                    x: e.pageX,
                    y: e.pageY
                }

                const change_x = current_mouse_position.x - lastMousePos.x;
                const change_y = current_mouse_position.y - lastMousePos.y;

                setLastMousePos(current_mouse_position);
                const newGlobalPos = {
                    x: GlobalPos.x += change_x,
                    y: GlobalPos.y += change_y
                }

                // GlobalPos = newGlobalPos;

                onStateChange({
                    ImageURL,
                    ContainerSize,
                    GlobalScale,
                    GlobalPos: newGlobalPos,
                    MaxScale,
                    FactorStep
                });
            });
        }
    }

    const handleMouseDown = (e: React.MouseEvent) => {
        setIsDragging(true);
        if (slideRef.current) {
            slideRef.current.style.cursor = 'move';
            slideRef.current.style.transition = 'transform 0s';
        }
        setLastMousePos({
            x: e.pageX,
            y: e.pageY
        });
    }

    const handleMouseUp = (e: React.MouseEvent) => {
        console.log('setIsDragging(false);');
        setIsDragging(false);
        if (!slideRef.current) return;
        slideRef.current.style.cursor = 'default';
        slideRef.current.style.transition = curr_transform;
    }

    return (
        <div className={styles.container}
             ref={containerRef}
             onMouseDown={handleMouseDown}
             onMouseUp={handleMouseUp}
            // onMouseOut={handleMouseUp}
            // onMouseLeave={handleMouseUp}
             onMouseMove={handleMouseMove}
        >
            <div className={styles.slide} ref={slideRef}>
                <img src={ImageURL} alt='unknown' className={styles.image}/>
            </div>
        </div>
    );
}

export default DragZoom;

