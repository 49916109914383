import React, {useMemo} from 'react';
import {Edge, Handle, useNodeId, useStore} from 'reactflow';

const selector = (state: any) => ({
    nodeInternals: state.nodeInternals,
    edges: state.edges
});

const CustomHandle = (props: any) => {
    const {nodeInternals, edges} = useStore(selector);
    const nodeId = useNodeId();  // ID of the current handle's node

    const isHandleConnectable = useMemo(() => {
        // Retrieve node and edge data
        const node = nodeInternals.get(nodeId);
        const handleId = props.id;  // Also retrieve handle ID from props

        if (!node || !handleId) {
            console.log('false - !node || !handleId');
            return false;
        }

        // Filter only edges connected to this handle
        const connectedEdges = edges.filter((edge: Edge) => {
            return (edge.source === nodeId && edge.sourceHandle === handleId) ||
                (edge.target === nodeId && edge.targetHandle === handleId);
        });

        // If isConnectable provided in props is a function
        if (typeof props.isConnectable === 'function') {
            return props.isConnectable({node, connectedEdges});
        }

        // If isConnectable provided in props is a number
        if (typeof props.isConnectable === 'number') {
            return connectedEdges.length < props.isConnectable;
        }

        // Otherwise, return the default isConnectable value
        return props.isConnectable;
    }, [nodeInternals, edges, nodeId, props]);

    return (
        <Handle {...props} isConnectable={isHandleConnectable}/>
    );
};

export default React.memo(CustomHandle);
