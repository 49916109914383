import React from 'react';
import footerStyles from './PageFooter.module.scss';
import aiFlowStyles from './PageFooterAIFlow.module.scss';

import ImageLogoTwitter from '../asset/logo_twitter.svg';
import ImageLogoInstagram from '../asset/logo_instagram.svg';
import ImageLogoGithub from '../asset/logo_github.svg';
import {useLocation} from "react-router-dom";
import routes from "../routes";

const PageFooter = () => {
    const location = useLocation();
    const isAIFlowPage = location.pathname.startsWith(routes.AIFlow);

    const styles = isAIFlowPage ? aiFlowStyles : footerStyles;

    return (
        <footer className={styles.footer}>
            <div className={styles.wrapper}>
                <div className={styles.left}>
                    © 2024 CNAPS.AI
                </div>
                <div className={styles.right}>
                    Contact CNAPS.AI
                    <a href='/'><img src={ImageLogoTwitter} alt='twitter' /></a>
                    <a href='/'><img src={ImageLogoInstagram} alt='instagram' /></a>
                    <a href='/'><img src={ImageLogoGithub} alt='github' /></a>
                </div>
            </div>
        </footer>
    );
}

export default PageFooter;
