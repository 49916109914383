import ApiClient from './apiClient';
import {API_URLS} from "../config/defaultConfig";
import {IAPIResponse, IAPIResponseDataEmpty, IUserContactMessage, UserProfileSave} from "../interfaces/d";

export const decodeJWT = (token: string) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

export const fetchUserDashboardData = async () => {
    const response = await ApiClient.get(API_URLS.UserDashboard);
    return response.data;
};

export const fetchUserFavoriteModels = async (page_number: number) => {
    const response = await ApiClient.get(API_URLS.UserFavoriteModels(page_number));
    return response.data;
}

export const fetchUserTransactions = async (page_number: number) => {
    const response = await ApiClient.get(API_URLS.UserTransactions(page_number));

    // https://dev.supremum.ai/swagger/index.html#/user/get_user_transactions
    // {
    //   "data": {
    //     "page": {
    //       "current": 0,
    //       "size": 0,
    //       "total": 0
    //     },
    //     "total_count": 0,
    //     "transactions": [
    //       {
    //         "created": 0,
    //         "id": "string",
    //         "images": [
    //           {
    //             "model": "string",
    //             "thumbnail": "string",
    //             "url": "string"
    //           }
    //         ],
    //         "is_public": true,
    //         "properties": "string",
    //         "type": "GenAI",
    //         "user_id": "string"
    //       }
    //     ]
    //   },
    //   "error": "string",
    //   "result": 0
    // }

    return response.data;
}

export const toggleModelFavorite = async (model_unique_name: string, toggle: boolean) => {
    const response = await ApiClient.post(API_URLS.FavoriteModelToggle, {
        model_id: model_unique_name,
        toggle: toggle
    });
    return response.data;
}

export const saveUserProfile = async (data: UserProfileSave) => {
    const formData = new FormData();

    data.avatar && formData.append('avatar', data.avatar);
    data.job && formData.append("job", data.job);
    data.home && formData.append("home", data.home);
    data.name && formData.append("name", data.name);
    data.location && formData.append("location", data.location);
    data.message && formData.append("message", data.message);
    data.is_public ? formData.append("is_public", 'true') : formData.append("is_public", 'false');

    const response = await ApiClient.post(API_URLS.ProfileEdit, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });

    return response.data;
}

export const postUserContact = async (data: IUserContactMessage) => {
    const response = await ApiClient.post(API_URLS.UserContactMessage, data);
    const response_data: IAPIResponse<IAPIResponseDataEmpty> = response.data;

    if (response_data.result === 0) {
        return true;
    } else {
        return false;
    }
}

