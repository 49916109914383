import React, {useEffect, useState} from 'react';
import ApiClient from "../services/apiClient";
import {UploadModalStage} from "../interfaces/d";
import {API_URLS, GENERATE_POLLING_INTERVAL_MS} from "../config/defaultConfig";
import styles from "./ImageGenerateProcess.module.scss";
import {useNavigate} from 'react-router-dom';
import routes from '../routes';

const ImageGenerateProcess: React.FC<{
    setModalStage: (stage: UploadModalStage) => void,
    TransactionId: string,
}> = ({ setModalStage, TransactionId }) => {

    const [userEmail, setUserEmail] = useState<string>('');
    const Navigate = useNavigate();

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserEmail(e.target.value);
    };

    useEffect(() => {
        const pollInterval = GENERATE_POLLING_INTERVAL_MS;

        const pollResultStatus = async () => {
            try {
                const response = await ApiClient.get(API_URLS.SISRModelProcessPolling(TransactionId));

                if (response.data.data.result === 1) {
                    clearInterval(polling);
                    fetchResult();
                }
            } catch (error) {
                console.error('Polling error:', error);
                clearInterval(polling);
            }
        };

        const fetchResult = async () => {
            try {
                const response = await ApiClient.get(API_URLS.SISRModelProcessResult(TransactionId));

                // Todo: remove confirm logic
                // const userConfirmed = window.confirm("Do you want to proceed to the model comparison page?");
                // if (userConfirmed) {
                //     setModalStage(UploadModalStage.OFF);
                //     Navigate(routes.modelComparison, {state: {data: response.data}});
                // }
                setModalStage(UploadModalStage.OFF);
                Navigate(routes.modelComparison, {state: {data: response.data}});
            } catch (error) {
                console.error('Error fetching result:', error);
            }
        };
        const polling = setInterval(pollResultStatus, pollInterval);

        return () => {
            clearInterval(polling);
        };
    }, [TransactionId]);

    const handleCloseClick = () => {
        setModalStage(UploadModalStage.OFF);
    }

    const sendEmail = async () => {
        const emailSendUrl: string = API_URLS.SendEmail;
        const formData = new URLSearchParams({
            transaction_id: TransactionId,
            email: userEmail,
        });

        try {
            const response = await ApiClient.post(emailSendUrl, formData.toString(), {
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });
            console.log('Email sent successfully:', response.data);

            setModalStage(UploadModalStage.SENT_MAIL);
        } catch (error) {
            console.error('Error sending email:', error);
        }
    };

    return (
        <div className={styles.main}>
            <div className={styles.close_button}>
                <img src='/images/upload_box_close_button.svg' alt='close' onClick={handleCloseClick} />
            </div>
            <div className={styles.current_step}>
                <div>Upload Image</div>
                <img src='/images/caret_right.svg' alt='Caret right' />
                <div className={styles.active_step}>Processing</div>
            </div>
            <div className={styles.processing_container}>
                <div className={styles.processing_image}>
                    <img src='/images/image_processing.svg' alt='Processing' />
                </div>
            </div>
            <div className={styles.email_form_container}>
                <div className={styles.email_desc}>
                    Notify me when you finish generating the image model
                </div>
                <div className={styles.email_input_container}>
                    <input type="email" className={styles.email_form} placeholder='Please enter email address'
                           autoComplete="off" onChange={handleEmailChange} />
                    <img className={styles.email_form_img} src='/images/send_button.svg' alt='Send' onClick={sendEmail} />
                </div>
            </div>

        </div>);
}

export default ImageGenerateProcess;
