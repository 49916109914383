import React from 'react';
import styles from './EvaluationsPage.module.scss';

const EvaluationsPage = () => {
    return (
        <div className={styles.main}>
            <div className={styles.left}>
                <img src='/images/evaluation_robot.png' alt='evaluations'/>
            </div>
            <div className={styles.right}>
                <div className={styles.title}>Image Model Evaluations</div>
                <div className={styles.desc}>text text text text text text text text text text text text text text text
                    text text text text text text ,
                </div>
                <button className={styles.evaluations_button}>Evaluations</button>
            </div>
        </div>
    )
}

export default EvaluationsPage;