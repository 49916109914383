import React, {useState} from 'react';
import styles from './ModalForGenAINode.module.scss';
import {CustomNodeGenAIModalState, ModalForGenAINodeProps} from "../../interfaces/d";

interface Property {
    key: string;
    value: string;
}

const ModalForGenAINode: React.FC<ModalForGenAINodeProps> = ({state, data, onSave, onCancel}) => {
    const [prompt, setPrompt] = useState(data?.prompt);
    const [negativePrompt, setNegativePrompt] = useState(data?.negativePrompt);
    const [properties, setProperties] = useState<Property[]>(data?.properties?.length ? data.properties : [{
        key: '',
        value: ''
    }]);
    const [showAdvanced, setShowAdvanced] = useState(false);

    const handleAdvancedClick = () => {
        setShowAdvanced(!showAdvanced);
    };

    const handleAddProperty = () => {
        setProperties([...properties, {key: '', value: ''}]);
    };

    const handlePropertyChange = (index: number, key: 'key' | 'value', value: string) => {
        const newProperties = properties.map((prop: Property, i: number) => {
            if (i === index) {
                return {...prop, [key]: value};
            }
            return prop;
        });
        setProperties(newProperties);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
        if (e.key === 'Enter') {
            handleAddProperty();
        }
    };

    const handleOnSaveClick = () => {
        const save_data = {
            id: data.id,
            prompt: prompt,
            negativePrompt: negativePrompt,
            properties: properties.filter(data => {
                return !!data.key.trim();
            })
        };

        onSave(save_data);
    }

    return (
        <div className={styles.genai_modal_main}>
            <div className={styles.contents}>
                <div className={styles.input_container}>
                    <div className={styles.prompt_container}>
                        {state !== CustomNodeGenAIModalState.OFF && (
                            <>
                                <textarea placeholder='Prompt' value={prompt} className={styles.prompt_box}
                                          onChange={(e) => setPrompt(e.target.value)}></textarea>
                                <textarea placeholder='Negative Prompt' value={negativePrompt}
                                          className={styles.prompt_box}
                                          onChange={(e) => setNegativePrompt(e.target.value)}></textarea>
                            </>
                        )}
                    </div>
                    {showAdvanced && (
                        <div className={styles.advanced_container}>
                            {properties.map((prop, index) => (
                                <div key={index} className={styles.property_input_container}>
                                    <div className={styles.property_key_value_container}>
                                        <input
                                            className={styles.property_input}
                                            type="text"
                                            placeholder="Property Key"
                                            value={prop.key}
                                            onKeyDown={(e) => handleKeyDown(e, index * 2)}
                                            onChange={(e) => handlePropertyChange(index, 'key', e.target.value)}
                                        />
                                        <input
                                            className={styles.property_input}
                                            type="text"
                                            placeholder="Property Value"
                                            value={prop.value}
                                            onKeyDown={(e) => handleKeyDown(e, index * 2 + 1)}
                                            onChange={(e) => handlePropertyChange(index, 'value', e.target.value)}
                                        />
                                    </div>
                                </div>
                            ))}
                            <button className={styles.add_property} onClick={handleAddProperty}>Add Property</button>
                        </div>
                    )}
                </div>

                <div className={styles.buttons}>
                    <div className={styles.advanced}>
                        <button onClick={handleAdvancedClick}>Advanced</button>
                    </div>
                    <div className={styles.save_cancel_container}>
                        <button className={styles.save} onClick={handleOnSaveClick}>Save</button>
                        <button className={styles.cancel} onClick={onCancel}>Cancel</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(ModalForGenAINode);