import React, {FC, MouseEvent, useCallback, useEffect, useRef, useState} from 'react';
import styles from './CompareImageSlider.module.scss';

export interface CompareImageSliderProps {
    SmallImageURL_1: string;
    SmallImageURL_2: string;

    LargeImageURL_1: string;
    LargeImageURL_2: string;

    initial_position?: number;
}

const CompareImageSlider: FC<CompareImageSliderProps> = ({
                                                             SmallImageURL_1, SmallImageURL_2,
                                                             LargeImageURL_1, LargeImageURL_2,
                                                             initial_position = 50
                                                         }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [sliderPosition, setSliderPosition] = useState<number>(initial_position); // Initial slider position as percentage
    const [aspectRatio, setAspectRatio] = useState<number>(1);
    const [smallImagesLoaded, setSmallImagesLoaded] = useState(false);
    const [largeImagesLoaded, setLargeImagesLoaded] = useState(false);
    const [fadeIn, setFadeIn] = useState(false);

    useEffect(() => {
        let loadedCount = 0;
        const checkImagesLoaded = () => {
            loadedCount++;
            if (loadedCount === 2) {
                setSmallImagesLoaded(true);
                setFadeIn(true);
            }
        };

        const img1 = new Image();
        img1.src = SmallImageURL_1;
        img1.onload = () => {
            setAspectRatio(img1.height / img1.width);
            checkImagesLoaded();
        };
        img1.onerror = checkImagesLoaded;

        const img2 = new Image();
        img2.src = SmallImageURL_2;
        img2.onload = checkImagesLoaded;
        img2.onerror = checkImagesLoaded;
    }, [SmallImageURL_1, SmallImageURL_2]);

    useEffect(() => {
        let loadedCount = 0;
        const checkImagesLoaded = () => {
            loadedCount++;
            if (loadedCount === 2) {
                setLargeImagesLoaded(true);
                setFadeIn(true);
            }
        };

        const img1 = new Image();
        img1.src = LargeImageURL_1;
        img1.onload = () => {
            setAspectRatio(img1.height / img1.width);
            checkImagesLoaded();
        };
        img1.onerror = checkImagesLoaded;

        const img2 = new Image();
        img2.src = LargeImageURL_2;
        img2.onload = checkImagesLoaded;
        img2.onerror = checkImagesLoaded;
    }, [LargeImageURL_1, LargeImageURL_2]);

    const handleMouseMove = useCallback((e: MouseEvent<HTMLDivElement>) => {
        if (containerRef.current) {
            const rect = containerRef.current.getBoundingClientRect();
            const offsetX = e.clientX - rect.left;
            const newSliderPosition = Math.max(0, Math.min((offsetX / rect.width) * 100, 100)); // Restrict slider position within container bounds
            setSliderPosition(newSliderPosition);
        }
    }, []);

    return (
        <div
            className={`${styles.container} ${fadeIn ? styles.fadeIn : ''}`}
            ref={containerRef}
            onMouseMove={handleMouseMove}
            style={{paddingBottom: `${aspectRatio * 100}%`}}
        >
            <div className={styles.imageContainer}>
                <img src={largeImagesLoaded ? LargeImageURL_1 : SmallImageURL_1} alt='Before' className={styles.image}
                     style={{clipPath: `inset(0 ${100 - sliderPosition}% 0 0)`}}/>
                <img src={largeImagesLoaded ? LargeImageURL_2 : SmallImageURL_2} alt='After' className={styles.image}
                     style={{clipPath: `inset(0 0 0 ${sliderPosition}%)`}}/>
            </div>
            <div className={styles.slider} style={{left: `${sliderPosition}%`}}/>
        </div>
    );
}

export default CompareImageSlider;
