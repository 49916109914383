import React, {useEffect, useState} from 'react';
import styles from './GenerativeAIModelProcess.module.scss';
import {GenerativeAIModelListProps, GenerativeAIPageStage} from "../interfaces/d";


const GenerativeAIModelProcess: React.FC<GenerativeAIModelListProps> = ({setPageStage}) => {
    const [ProcessPercentage, setProcessPercentage] = useState(0);
    const handleEmailChange = () => {
    }
    const sendEmail = () => {
    }

    useEffect(() => {
        // Todo: Remove test codes and use Polling API
        setTimeout(() => {
            setProcessPercentage(30);
        }, 500);
        setTimeout(() => {
            setProcessPercentage(70);
        }, 1000);
        setTimeout(() => {
            setProcessPercentage(100);
        }, 1500);

        setTimeout(() => {
            // const isConfirm = window.confirm("move to result page");
            // if (isConfirm) {
            //     setPageStage(GenerativeAIPageStage.RESULT);
            // }
            setPageStage(GenerativeAIPageStage.RESULT);
        }, 2500);
    }, []);

    return (
        <div className={styles.wrapper}>
            <div className={styles.processing_container}>
                <div className={styles.processing_image}>
                    <img src='/images/image_processing.svg' alt='Processing'/>
                </div>
                <div className={styles.progress_container}>
                    <progress value={ProcessPercentage} max="100"></progress>
                    <div className={styles.percentage}>{ProcessPercentage}%</div>
                </div>
            </div>


            <div className={styles.email_form_container}>
                <div className={styles.email_desc}>
                    Notify me when you finish generating the image model
                </div>
                <div className={styles.email_input_container}>
                    <input type="email" className={styles.email_form} placeholder='Please enter email address'
                           autoComplete="off" onChange={handleEmailChange}/>
                    <img className={styles.email_form_img} src='/images/send_button.svg' alt='Send'
                         onClick={sendEmail}/>
                </div>
            </div>
        </div>
    );
}

export default GenerativeAIModelProcess;