import React from 'react';
import {UploadModalStage} from "../interfaces/d";
import styles from "./SendMail.module.scss";

const SendMail: React.FC<{ setModalStage: (stage: UploadModalStage) => void }> = ({ setModalStage }) => {

    const handleCloseClick = () => {
        setModalStage(UploadModalStage.OFF);
    }

    return (
        <div className={styles.main}>
            <div className={styles.close_button}>
                <img src='/images/upload_box_close_button.svg' alt='close' onClick={handleCloseClick} />
            </div>
            <div className={styles.current_step}>
                <div>Upload Image</div>
                <img src='/images/caret_right.svg' alt='Caret right' />
                <div className={styles.active_step}>Processing</div>
            </div>

            <div className={styles.email_image_container}>
                <img className={styles.image} src='/images/sent_mail.svg' alt='sent mail' />
            </div>

            <button className={styles.done_button} onClick={handleCloseClick}>Got It! See your model soon</button>
        </div>
    );
}

export default SendMail;
