import React from 'react';
import styles from './IndexPage.module.scss';
import {Link} from "react-router-dom";
import routes from "../routes";
import CompareImageSlider from "../components/CompareImageSlider";

const IndexPage = () => {
    return (
        <main className={styles.main}>
            <div className={styles.wrapper}>
                <div className={styles.beta_notice_container}>
                    <div className={styles.beta}>BETA</div>
                    <div className={styles.beta_description}>
                        This service is currently in testing phase
                    </div>
                </div>
                <div className={styles.carousel}>
                    <div className={styles.carousel_title}>
                        Develop Your AI flow Without Coding<br/>
                        Execute and Deploy the AI flow Instantly
                    </div>
                    <div className={styles.carousel_desc}>
                        Create your AI flow effortlessly by dragging and connecting AI models
                    </div>
                    <div className={styles.carousel_buttons}>
                        <Link className={styles.carousel_buttons_get_started} to={routes.AIFlow}>Get Started</Link>
                    </div>
                </div>

                <div className={styles.content1}>
                    <div className={styles.carousel_title}>
                        AI Flow Examples
                    </div>
                    <div className={styles.content1_row}>
                        {/*<Link to={routes.AIFlow + '/copy/4adb3e62-854b-4f8f-ac3e-ee1c2b6dee6c'}>*/}
                            <div className={styles.content1_item}>
                                <div className={styles.content1_item_container}>
                                    <CompareImageSlider
                                        SmallImageURL_1='/images/index/sky_low_res_noisy_jpeg_resized.jpg'
                                        SmallImageURL_2='/images/index/sky_restore_denoise_sisr_resized.jpg'
                                        LargeImageURL_1='/images/index/sky_low_res_noisy_jpeg.webp'
                                        LargeImageURL_2='/images/index/sky_restore_denoise_sisr.webp'
                                    />
                                </div>
                                Holistic<br/>Image<br/>Enhancement
                            </div>
                        {/*</Link>*/}
                        {/*<Link to={routes.AIFlow + '/copy/93373656-2700-4463-9a31-53ccb880e700'}>*/}
                            <div className={styles.content1_item}>
                                <div className={styles.content1_item_container}>
                                    <CompareImageSlider
                                        SmallImageURL_1='/images/index/blurry_city_detr_before_text_resized.jpg'
                                        SmallImageURL_2='/images/index/blurry_city_detr_after_resized.jpg'
                                        LargeImageURL_1='/images/index/blurry_city_detr_before_text.webp'
                                        LargeImageURL_2='/images/index/blurry_city_detr_after.webp'
                                    />
                                </div>
                                Enhanced<br/>Object<br/>Detection
                            </div>
                        {/*</Link>*/}
                    </div>
                    <div className={styles.content1_row}>
                        {/*<Link to={routes.AIFlow + '/copy/34b792e4-56ca-432e-9f85-31acc782346c'}>*/}
                            <div className={styles.content1_item}>
                                <div className={styles.content1_item_container}>
                                    <CompareImageSlider SmallImageURL_1='/images/index/genai_txt2img_before.webp'
                                                        SmallImageURL_2='/images/index/genai_txt2img_after_resized.jpg'
                                                        LargeImageURL_1='/images/index/genai_txt2img_before.webp'
                                                        LargeImageURL_2='/images/index/genai_txt2img_after.webp'
                                    />
                                </div>
                                High Resolution<br/>GenAI<br/>Text to Image
                            </div>
                        {/*</Link>*/}
                        {/*<Link to={routes.AIFlow + '/copy/f628c52e-5359-43e5-b024-819ddae117b1'}>*/}
                            <div className={styles.content1_item}>
                                <div className={styles.content1_item_container}>
                                    <CompareImageSlider SmallImageURL_1='/images/index/pexels_bw_before_resized.jpg'
                                                        SmallImageURL_2='/images/index/pexels_bw_after_resized.jpg'
                                                        LargeImageURL_1='/images/index/pexels_bw_before.webp'
                                                        LargeImageURL_2='/images/index/pexels_bw_after.webp'
                                    />
                                </div>
                                High Resolution<br/>Colorization
                            </div>
                        {/*</Link>*/}
                    </div>
                </div>

                <div className={styles.content2}>
                    <div className={styles.content2_left}>
                        <div className={styles.content2_title}>
                            Offers support for 165 AI models, spanning 24 AI categories and 3 modalities
                        </div>
                        <div className={styles.content2_desc}>
                            You can explore and integrate all possible combinations of AI models,
                            AI categories, internal and external APIs, and input-output modalities.
                        </div>
                    </div>
                    <div className={styles.content2_right}>
                        <img src='/images/index/models.svg' alt='AI Models'/>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default IndexPage;
