import * as amplitude from '@amplitude/analytics-browser';
import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {GoogleClientID} from "./config/defaultConfig";
import routes from './routes';

import {AuthProvider} from './services/authService';

import PageHeader from './components/PageHeader';
import PageFooter from './components/PageFooter';
import ProtectedRoute from "./components/ProtectedRoute";

import IndexPage from './views/IndexPage';
import SignInPage from './views/SignInPage';
import ModelListPage from "./views/ModelListPage";
import ModelComparisonPage from "./views/ModelComparisonPage";
import EvaluationsPage from "./views/EvaluationsPage";
import PageDashboard from './views/DashboardPage';
import GenerativeAIPage from "./views/GenerativeAIPage";
import ComingSoonPage from "./views/ComingSoonPage";
import AIFlow from "./views/AIFlow";
import AboutPageEmailForm from "./views/AboutPageEmailForm";
import PrivacyPolicy from "./views/PrivacyPolicy"
import PricingPage from "./views/PricingPage";
import DownloadPage from "./views/DownloadPage";

import './App.css';

amplitude.init('d18f044a1e74b03f65ff6b52c6373257', {
    defaultTracking: true
});

function App() {
    return (
        <GoogleOAuthProvider clientId={GoogleClientID}>
            <AuthProvider>
                <Router>
                    <PageHeader />
                    <Routes>
                        <Route path={routes.home} element={<IndexPage />} />
                        <Route path={routes.signIn} element={<SignInPage />} />
                        <Route path={routes.modelList} element={<ModelListPage />} />
                        <Route path={routes.modelComparison} element={<ModelComparisonPage />} />
                        <Route path={routes.dashboard} element={
                            <ProtectedRoute>
                                <PageDashboard/>
                            </ProtectedRoute>
                        }/>
                        <Route path={routes.about} element={<AboutPageEmailForm/>}/>
                        <Route path={routes.privacyPolicy} element={<PrivacyPolicy/>}/>
                        {/*<Route path={routes.about} element={<ComingSoonPage/>}/>*/}
                        <Route path={routes["generative-ai"]} element={<GenerativeAIPage/>}/>
                        <Route path={routes.datasets} element={<ComingSoonPage/>}/>
                        <Route path={routes.apiDocs} element={<ComingSoonPage/>}/>
                        <Route path={routes.evaluations} element={<EvaluationsPage/>}/>
                        <Route path={routes.AIFlow} element={<AIFlow/>}/>
                        <Route path={`${routes.AIFlow}/:uuid`} element={<AIFlow/>}/>
                        <Route path={`${routes.AIFlow}/copy/:uuid`} element={<AIFlow isCopy={true}/>}/>
                        <Route path={routes.pricing} element={<PricingPage/>}/>
                        <Route path={routes.download} element={<DownloadPage/>}/>
                    </Routes>
                    <PageFooter />
                </Router>
            </AuthProvider>
        </GoogleOAuthProvider>
    );
}

export default App;
