import React from 'react';
import styles from './PrivacyPolicy.module.scss';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className={styles.main}>
            <div className={styles.wrapper}>
                <h2>Privacy Policy Agreement</h2>
                <ul>
                    <li>Personal Information Collection Items: We collect your name, email address, and message content
                        when you contact us.
                    </li>
                    <li>Purpose of Collecting Personal Information: The collected personal information is used to
                        respond to customer inquiries and provide our services.
                    </li>
                    <li>Retention Period of Personal Information: We will retain your personal information until the
                        completion of the inquiry or as required by applicable laws.
                    </li>
                    <li>Providing and Sharing Personal Information: We do not provide or share your personal information
                        with third parties, except as required by law or with your explicit consent.
                    </li>
                    <li>Rights of Information Subjects: You have the right to withdraw your consent, request access to
                        your information, and request correction or deletion of your information.
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default PrivacyPolicy;
