import React from 'react';
import styles from './IsLoading.module.scss';
import logoImage from '../asset/logo_symbol.svg';

const IsLoading = () => {
    return (
        <div className={styles.overlayContainer}>
            <div className={styles.fullScreenOverlay}>
                <img src={logoImage} className={styles.logoFloat} alt="Loading..."/>
            </div>
        </div>
    );
};
export default IsLoading;