import React, {memo, useEffect, useMemo, useRef} from 'react';
import {NodeProps, Position, useUpdateNodeInternals} from 'reactflow';
import styles from './DefaultCustomNode.module.scss';
import {CustomNodeStatus, INodeData} from "../../interfaces/d";
import CustomHandle from "./CustomHandle";
import ParametersForm from "./ParametersForm";
import nodeManager from "./NodeManager";


const Txt2ImgInputNode: React.FC<NodeProps<INodeData>> = ({data}) => {
    const updateNodeInternals = useUpdateNodeInternals();
    const nodeRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (!nodeRef.current) return;
        switch (data.status) {
            case CustomNodeStatus.PREPARING:
                nodeRef.current.className = `${styles.customNode} ${styles.preparing}`;
                break;
            case CustomNodeStatus.PROCESSING:
                nodeRef.current.className = `${styles.customNode} ${styles.processing}`;
                break;
            case CustomNodeStatus.COMPLETED:
                nodeRef.current.className = `${styles.customNode} ${styles.completed}`;
                break;
        }
    }, [data.status])

    const onClickHandler = (e: React.MouseEvent) => {
        e.stopPropagation();
    }

    const inputHandles = useMemo(() => data.input?.names?.map((inputName: string, index: number) => {
        return (
            <CustomHandle
                key={`input-${index}`}
                type="target"
                position={Position.Left}
                id={`input-${inputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={1}
                style={{
                    background: `${nodeManager.colorByHandleName(inputName)}`
                }}
                data-tooltip-id="DefaultNodeToolTip"
                data-tooltip-content={inputName}
            />
        )
    }), [data.input]);

    const outputHandles = useMemo(() => data.output?.names?.map((outputName: string, index: number) => {
        setTimeout(() => {
            updateNodeInternals(data.id);
        }, 0);

        return (
            <CustomHandle
                key={`output-${index}`}
                type="source"
                position={Position.Right}
                id={`output-${outputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={true}
                style={{
                    background: `${nodeManager.colorByHandleName(outputName)}`
                }}
                data-tooltip-id="DefaultNodeToolTip"
                data-tooltip-content={outputName}
            />
        )
    }), [data.output]);

    return (
        <div className={`${styles.customNode} ${styles.preparing}`} ref={nodeRef}>
            {inputHandles && inputHandles}
            <div className={`${styles.node_header} customDragHandle`} style={{cursor: 'grab'}} onClick={onClickHandler}>
                <label>{data.label}</label>
            </div>

            <div className={styles.node_content}>
                <ParametersForm parameters={data.parameters!} onChange={data.onChange}/>
            </div>

            {outputHandles && outputHandles}
        </div>
    );
};

export default memo(Txt2ImgInputNode);
