import React, {useState} from 'react';

interface ImageWithFallbackProps {
    src: string;
    alt: string;
    className?: string;
    onError?: () => void;
    onClickZoom?: () => void;
    onClick?: () => void;
}

const ImageWithFallback: React.FC<ImageWithFallbackProps> = ({
                                                                 src,
                                                                 alt,
                                                                 className,
                                                                 onError,
                                                                 onClickZoom,
                                                                 onClick
                                                             }) => {
    const [hasError, setHasError] = useState(false);

    const handleError = () => {
        setHasError(true);
        if (onError) onError();
    };

    const handleImageClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onClick) onClick()
    }

    const handleZoomClick = (e: React.MouseEvent) => {
        e.preventDefault();
        if (onClickZoom) onClickZoom();
    };

    const defaultImage = '/images/no_image.svg';

    return (
        <div style={{position: 'relative', display: 'inline-block'}}>
            <img
                src={hasError ? defaultImage : src}
                alt={alt}
                className={className}
                onError={handleError}
                style={{display: 'block'}}
                onClick={handleImageClick}
            />
            {!hasError && (
                <img
                    src="/images/aiflow/icon_image_zoom.svg"
                    alt="Zoom Icon"
                    style={{
                        position: 'absolute',
                        top: '0',
                        right: '-10px',
                        width: '80px',
                        height: '80px',
                        cursor: 'pointer',
                        opacity: '0.8'
                    }}
                    onClick={handleZoomClick}
                />
            )}
        </div>
    );
};

export default ImageWithFallback;
