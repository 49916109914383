import ApiClient from './apiClient';
import {
    ErrorCode,
    IAPIImageUploadResponse,
    IAPIImageURLByChecksum,
    IAPILoadFlow,
    IAPILoadFlowList,
    IAPIPollFlowData,
    IAPIResponse,
    IAPIResponseFlowUpload,
    IEdgeMapping,
    IFlowNodeList,
    IFlowNodeSource
} from '../interfaces/d';
import {API_URLS, IDLE_SECONDS_BEFORE_FLOW_UPLOAD} from "../config/defaultConfig";
import {Edge, Node} from "reactflow";
import axios from "axios";

let update_flow_timer: NodeJS.Timeout;

export const uploadFlowData = async (flow_created_time: number, flow_id: string, flow_name: string = '', data: IFlowNodeSource[], uploadCompleteHandler: any): Promise<void> => {
    clearTimeout(update_flow_timer);
    console.log(`Waiting for ${IDLE_SECONDS_BEFORE_FLOW_UPLOAD} seconds of inactivity before uploading flow data.`);

    return new Promise((resolve, reject) => {
        update_flow_timer = setTimeout(async () => {
            try {
                if (!flow_id) {
                    reject(new Error('empty flow id'));
                    return;
                }
                const response: IAPIResponse<IAPIResponseFlowUpload> = await ApiClient.post(API_URLS.UploadFlow, {
                    created: flow_created_time,
                    id: flow_id,
                    last_modified: Date.now(),
                    name: flow_name,
                    nodes: data
                });
                // console.log(`Flow data uploaded.`);
                uploadCompleteHandler();
                resolve();
            } catch (e) {
                console.error('Error uploading flow data:', e);
                if (axios.isAxiosError(e)) {
                    if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                        reject(new Error(e.response.data.error.msg));
                    }
                } else {
                    reject(new Error('An unknown error occurred'));
                }
            }
        }, IDLE_SECONDS_BEFORE_FLOW_UPLOAD * 1000);
    });
};

export const pollFlowData = async (flow_id: string = 'test-id') => {
    try {
        const response = await ApiClient.get(API_URLS.FlowPolling(flow_id));
        const data: IAPIResponse<IAPIPollFlowData> = response.data;

        return data.data;
    } catch (e) {
        console.error('Error pollingFlowData', e);
    }
}

export const loadFlowList = async () => {
    try {
        const response = await ApiClient.get(API_URLS.LoadFlowList);
        const data: IAPIResponse<IAPILoadFlowList> = response.data;

        return data.data.items;
    } catch (e) {
        console.error('Error loadFlowList', e);
    }
}

export const loadFlowData = async (flow_id: string) => {
    try {
        const response = await ApiClient.get(API_URLS.LoadFlow(flow_id));
        const data: IAPIResponse<IAPILoadFlow> = response.data;

        return data.data;
    } catch (e) {
        console.error('Error loadFlowData', e);
    }
}

export const getImageURLbyCheckSum = async (image_checksum: string) => {
    try {
        const response = await ApiClient.get(API_URLS.FlowImageChecksum(image_checksum));
        const data: IAPIResponse<IAPIImageURLByChecksum> = response.data;

        if (data.result === 0) {
            return data.data;
        } else {
            console.error(data.error);
        }

    } catch (e) {
        console.error('Error image checksum:', e);
    }
}

export const uploadImageByImageLoader = async (file: File) => {
    try {
        const formData = new FormData();
        formData.append('file', file);

        const response = await ApiClient.post(API_URLS.FlowImageUpload, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        const data: IAPIResponse<IAPIImageUploadResponse> = response.data;
        console.log('IAPIImageUploadResponse data', data)

        if (data.result === 0) {
            return data.data;
        } else {
            console.error('Image upload failed:', data.error);
        }
    } catch (e) {
        console.error('Error image upload:', e);
    }
}

// export const getImageFromURL = async (image_url: string): Promise<File | null> => {
//     try {
//         const response = await fetch(image_url);
//         if (!response.ok) {
//             throw new Error(`Failed to fetch image: ${response.statusText}`);
//         }
//         const blob = await response.blob();
//         const file = new File([blob], "image.jpg", {type: blob.type});
//
//         return file;
//     } catch (e) {
//         console.error(e);
//         return null;
//     }
// };

export const getFlowNodeList = async (): Promise<IFlowNodeSource[] | undefined> => {
    try {
        const response = await ApiClient.get(API_URLS.FlowNodeList);
        const data: IAPIResponse<IFlowNodeList> = response.data;
        if (data.result === ErrorCode.NoError) {
            const items = data.data.items.map(item => {
                const node_definition = item.node_definition;
                node_definition.description = item.metadata.description;
                node_definition.related_links = item.metadata.related_links;
                return item.node_definition;
            })
            return items;
        }
    } catch (e) {
        console.error(e);
        return undefined;
    }
};

export const renameFlow = async (flow_id: string, new_name: string): Promise<void> => {
    try {
        const response = await ApiClient.post(API_URLS.RenameFlow(flow_id), {
            name: new_name,
        });
        return response.data.data;
    } catch (e) {
        console.error('Error rename flow:', e);
        if (axios.isAxiosError(e)) {
            if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                throw new Error(e.response.data.error.msg);
            }
        }
        throw new Error('An unknown error occurred');
    }
};

export const deleteFlow = async (flow_id: string): Promise<void> => {
    try {
        const response = await ApiClient.post(API_URLS.DeleteFlow(flow_id));
        return response.data.data;
    } catch (e) {
        console.error('Error deleteFlow:', e);
        if (axios.isAxiosError(e)) {
            if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                throw new Error(e.response.data.error.msg);
            }
        }
        throw new Error('An unknown error occurred');
    }
};

export const trashFlow = async (flow_id: string): Promise<void> => {
    try {
        const response = await ApiClient.post(API_URLS.TrashFlow(flow_id));
        return response.data.data;
    } catch (e) {
        console.error('Error deleteFlow:', e);
        if (axios.isAxiosError(e)) {
            if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                throw new Error(e.response.data.error.msg);
            }
        }
        throw new Error('An unknown error occurred');
    }
};

export const restoreFlow = async (flow_id: string): Promise<void> => {
    try {
        const response = await ApiClient.post(API_URLS.RestoreFlow(flow_id));
        return response.data.data;
    } catch (e) {
        console.error('Error deleteFlow:', e);
        if (axios.isAxiosError(e)) {
            if (e.response?.data.error?.code && e.response?.data.error?.msg) {
                throw new Error(e.response.data.error.msg);
            }
        }
        throw new Error('An unknown error occurred');
    }
};


export const convertToIFlowNodes = (nodes: Node[], edges: Edge[]): IFlowNodeSource[] => {
    // console.log(`convertToIFlowNodes nodes`, nodes, `edges`, edges)
    const edgeMapping: IEdgeMapping = edges.reduce((acc: IEdgeMapping, edge: any) => {
        if (!acc[edge.source]) acc[edge.source] = {inputs: [], outputs: []};
        if (!acc[edge.target]) acc[edge.target] = {inputs: [], outputs: []};

        acc[edge.source].outputs.push(edge.target);
        acc[edge.target].inputs.push(edge.source);

        return acc;
    }, {});

    return nodes.map(node => ({
        created: node.data.created,
        description: node.data.description,
        related_links: node.data.related_links,
        id: node.id,
        node_name: node.data.node_name,
        node_type: node.data.node_type,
        status: node.data.status,
        category: node.data.category,
        model_id: node.data.model_id,
        input_ids: edgeMapping[node.id]?.inputs || [],
        output_ids: edgeMapping[node.id]?.outputs || [],
        parameters: node.data.parameter_values,
        result: {},
        ui: {
            x: node.position.x,
            y: node.position.y,
            connection: node.data.ui.connection,
        }
    }));
};
