import React, {useEffect, useRef, useState} from 'react';
import styles from './GenerativeAIModelList.module.scss';
import {GenerativeAIModelListProps, GenerativeAIPageStage, IModelModel} from "../interfaces/d";
import {fetchGenAIModels} from "../services/modelService";


const GenerativeAIModelList: React.FC<GenerativeAIModelListProps> = ({setPageStage}) => {
    const refModelScrollBox = useRef<HTMLDivElement>(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [GenAIModels, setGenAIModels] = useState<IModelModel[]>([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const {filteredModels} = await fetchGenAIModels();
                setGenAIModels(filteredModels)

            } catch (error) {
                console.error("Error fetching GenAI Filtered models", error);
            }
        };
        fetchData();

    }, []);

    const startDragging = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.preventDefault();

        setIsDragging(true);
        setStartX(e.pageX - (refModelScrollBox.current?.offsetLeft ?? 0));
        setScrollLeft(refModelScrollBox.current?.scrollLeft ?? 0);
    };

    const stopDragging = () => {
        setIsDragging(false);
    };

    const onDrag = (e: MouseEvent) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - (refModelScrollBox.current?.offsetLeft ?? 0);
        const walk = (x - startX) * 2;
        refModelScrollBox.current!.scrollLeft = scrollLeft - walk;
    };

    const ModelCard = ({model}: { model: IModelModel }) => {
        return (
            <div className={styles.model_card}>
                <div className={styles.model_image}>
                    <img src='/images/GenAI-Sample-Girl.svg' alt='Model'/>
                </div>
                <div className={styles.modelInfo}>
                    <div className={styles.model_name}>{model.model_name.replace('GenAI-', '')}</div>
                    <div className={styles.model_owner}>Owner {model.license.owner}</div>
                </div>
            </div>
        );
    };

    useEffect(() => {
        const scrollBox = refModelScrollBox.current;

        const handleMouseDown = (e: MouseEvent) => {
            startDragging(e as unknown as React.MouseEvent<HTMLDivElement, MouseEvent>);
        };

        const handleWheel = (e: WheelEvent) => {
            if (scrollBox) {
                // noinspection JSSuspiciousNameCombination
                scrollBox.scrollLeft += e.deltaY * 3;
                e.preventDefault();
            }
        };

        scrollBox?.addEventListener('mousedown', handleMouseDown as EventListener);
        scrollBox?.addEventListener('wheel', handleWheel, {passive: false});
        document.addEventListener('mousemove', onDrag);
        document.addEventListener('mouseup', stopDragging);

        return () => {
            scrollBox?.removeEventListener('mousedown', handleMouseDown as EventListener);
            scrollBox?.removeEventListener('wheel', handleWheel);
            document.removeEventListener('mousemove', onDrag);
            document.removeEventListener('mouseup', stopDragging);
        };
    }, [startDragging]);


    const handleGenerateClick = () => {
        setPageStage(GenerativeAIPageStage.PROCESSING);
    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.title_wrapper}>
                <div className={styles.title}>
                    Generative Models
                </div>
            </div>

            <div
                className={styles.models_scroll_box}
                ref={refModelScrollBox}
                style={{cursor: isDragging ? 'grab' : 'auto'}}
            >
                {(GenAIModels).map((item) => (
                    <ModelCard key={item.id} model={item}/>
                ))}
            </div>
            <div className={styles.box_title_wrapper}>
                <div className={styles.box_title}>Prompt</div>
                <button className={styles.box_title_option}><img src='/images/generative_icon_advanced.svg'
                                                                 alt='advanced option'/> Advanced
                </button>
            </div>
            <textarea
                className={styles.prompt_box}
                placeholder="(best quality, masterpiece), 1girl, cyberpunk, neon, off shoulder"
            />

            <div className={styles.box_title_wrapper}>
                <div className={styles.box_title}>Negative Prompt</div>
            </div>
            <textarea
                className={styles.negative_prompt_box}
                placeholder="text,blurry"
            />
            <button className={styles.generate_button} onMouseDown={handleGenerateClick}>Generate</button>
        </div>
    );
}

export default GenerativeAIModelList;
