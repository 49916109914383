import React, {useEffect, useMemo, useReducer, useRef, useState,} from 'react';
import {NodeProps, Position, useStore, useUpdateNodeInternals, useViewport} from 'reactflow';
import styles from './DefaultCustomNode.module.scss';
import {CustomNodeStatus, INodeData} from '../../interfaces/d';
import CustomHandle from './CustomHandle';
import nodeManager, {NodeManagerClass} from './NodeManager';
import {Tooltip} from 'react-tooltip';

const CustomNode: React.FC<NodeProps<INodeData>> = ({id, data}) => {
    const nodeRef = useRef<HTMLDivElement>(null);
    const [nodeHeight, setNodeHeight] = useState(0);
    const [nodeState, setNodeState] = useState<CustomNodeStatus>(data.status);

    const [isConnected, setIsConnected] = useState<boolean>(false)
    const edges = useStore(state => state.edges);

    useEffect(() => {
        const checkConnections = () => {
            const connectedEdges = edges.filter(edge => edge.source === id || edge.target === id);
            if (connectedEdges.length === 0) {
                setNodeState(CustomNodeStatus.PREPARING);
                setIsConnected(false);
                console.log('disconnected');
            } else {
                setIsConnected(connectedEdges.length > 0);
                console.log('connected');
            }
        };

        checkConnections();

    }, [edges, id]);

    const [sliderValue, setSliderValue] = useState(0);
    const [isEditing, setIsEditing] = useState(false)

    const [handlePosition, setHandlePosition] = useState(0);
    const sliderRef = useRef<HTMLDivElement>(null);
    const updateNodeInternals = useUpdateNodeInternals();
    const {zoom} = useViewport();


    let handleHeight = 30;
    let handleSpacing = 0;

    const handleCloseClick = () => {
        if (data.onClose) {
            data.onClose(data.id);
        }
    }

    useEffect(() => {
        const updateSliderWidth = () => {
            if (sliderRef.current) {
                const sliderWidth = sliderRef.current.clientWidth;
                const initialHandlePosition = sliderWidth * 0.5;
                setHandlePosition(initialHandlePosition);
            }
        };

        updateSliderWidth();

        const resizeObserver = new ResizeObserver(updateSliderWidth);
        if (sliderRef.current) {
            resizeObserver.observe(sliderRef.current);
        }

        if (data.result?.float) {
            setSliderValue(data.result.float);

            const floatMatch = data.output.types[0].match(/Float\[(.*),(.*),(.*)\]/);
            let min: number, max: number, step: number;
            if (floatMatch) {
                min = parseFloat(floatMatch[1]);
                max = parseFloat(floatMatch[2]);
                step = parseFloat(floatMatch[3]);
                console.log(`${min}, ${max}, ${step}`);
                const slider = sliderRef.current;
                if (slider) {
                    const sliderRect = slider.getBoundingClientRect();
                    const sliderWidth = sliderRect.width;

                    setTimeout(() => {
                        setHandlePosition((data.result.float - min) / (max - min) * sliderWidth);
                        console.log('setHandlePosition', (data.result.float - min) / (max - min) * sliderWidth)
                    }, 0);
                }
            }
        }

        return () => {
            if (sliderRef.current) {
                resizeObserver.unobserve(sliderRef.current);
            }
        };
    }, []);

    const handleMouseDown = (e: React.MouseEvent) => {
        if (!isConnected || nodeState === CustomNodeStatus.COMPLETED) return;

        const slider = sliderRef.current;
        if (!slider) return;

        const floatMatch = data.output.types[0].match(/Float\[(.*),(.*),(.*)\]/);
        let min: number, max: number, step: number;
        if (floatMatch) {
            min = parseFloat(floatMatch[1]);
            max = parseFloat(floatMatch[2]);
            step = parseFloat(floatMatch[3]);
            console.log(`${min}, ${max}, ${step}`);
        } else {
            setSliderValue(0);
            return;
        }

        const sliderRect = slider.getBoundingClientRect();
        const sliderWidth = sliderRect.width / zoom;

        const onMouseMove = (e: MouseEvent) => {
            const newLeft = (e.clientX - sliderRect.left) / zoom;
            const clampedLeft = Math.max(0, Math.min(newLeft, sliderWidth));

            let newValue = clampedLeft / sliderWidth;
            newValue = min + newValue * (max - min);
            newValue = Math.round(newValue / step) * step;
            newValue = Math.max(min, Math.min(newValue, max));

            setHandlePosition((newValue - min) / (max - min) * sliderWidth);
            setSliderValue(parseFloat(newValue.toFixed(2)));

            const newParameterValues = {
                ...data.parameter_values,
                float: newValue,
            };
            data.parameter_values = newParameterValues;
            setNodeState(CustomNodeStatus.PREPARING);
            data.onChange({
                ...data,
                parameter_values: newParameterValues,
                status: CustomNodeStatus.PREPARING
            });

            if (sliderRef.current) {
                const newHandlePosition = (newValue - min) / (max - min) * sliderWidth;
                setHandlePosition(newHandlePosition);
            }
        };

        const onMouseUp = () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
        };

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
    };


    const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!isConnected || nodeState === CustomNodeStatus.COMPLETED) return;

        const value = parseFloat(e.target.value);
        setSliderValue(value);

        const newParameterValues = {
            ...data.parameter_values,
            float: value,
        };
        data.parameter_values = newParameterValues;
        setNodeState(CustomNodeStatus.PREPARING);
        data.onChange({
            ...data,
            parameter_values: newParameterValues,
            status: CustomNodeStatus.PREPARING
        });

        if (sliderRef.current) {
            const sliderWidth = sliderRef.current.clientWidth;
            const newHandlePosition = Math.max(0, Math.min(value * sliderWidth, sliderWidth));
            setHandlePosition(newHandlePosition);
        }
    };

    const handleBlur = () => {
        setIsEditing(false);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            setIsEditing(false);
        }
    };

    useEffect(() => {
        const node = nodeRef.current;
        if (node) {
            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    setNodeHeight(entry.contentRect.height);
                }
            });

            resizeObserver.observe(node);
            return () => resizeObserver.unobserve(node);
        }
    }, []);

    useEffect(() => {
        setNodeState(data.status);
        switch (data.status) {
            case CustomNodeStatus.PREPARING:
            case CustomNodeStatus.PROCESSING:
            case CustomNodeStatus.COMPLETED:
                if (nodeRef.current) {
                    nodeRef.current.className = `${styles.customNode} ${styles[data.status.toLowerCase()]}`;
                }
                break;
        }
    }, [data.status]);

    const [, forceUpdate] = useReducer(x => x + 1, 0);
    useEffect(() => {
        if (nodeHeight > 0) {
            forceUpdate();
        }
    }, [nodeHeight]);

    let inputHandleCount = data.input?.names?.length || 0;
    let inputHandleTotalHeight = (inputHandleCount * handleHeight) + ((inputHandleCount - 1) * handleSpacing);
    let inputInitialTopOffset = nodeHeight > 0 ? (nodeHeight / 2) - (inputHandleTotalHeight / 2) : 0;
    inputInitialTopOffset += 18;

    let outputHandleCount = data.output?.names?.length || 0;
    let outputHandleTotalHeight = (outputHandleCount * handleHeight) + ((outputHandleCount - 1) * handleSpacing);
    let outputInitialTopOffset = nodeHeight > 0 ? (nodeHeight / 2) - (outputHandleTotalHeight / 2) : 0;
    outputInitialTopOffset += 18;

    const nodeClassName = `${styles.customNode} ${styles[nodeState]}`;
    const inputHandles = useMemo(() => data.input?.names?.map((inputName: string, index: number) => {
        return (
            <CustomHandle
                key={`input-${index}`}
                type="target"
                position={Position.Left}
                id={`input-${inputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={1}
                style={{
                    top: `${inputInitialTopOffset + ((handleHeight + handleSpacing) * index)}px`,
                    background: `${nodeManager.colorByHandleName(inputName)}`
                }}
                data-tooltip-id="DefaultNodeToolTip"
                data-tooltip-content={inputName}
            />
        );
    }), [data.input, nodeHeight, handleHeight, handleSpacing]);

    const outputHandles = useMemo(() => data.output?.names?.map((outputName: string, index: number) => {
        setTimeout(() => {
            updateNodeInternals(data.id);
        }, 0);

        return (
            <CustomHandle
                key={`output-${index}`}
                type="source"
                position={Position.Right}
                id={`output-${outputName}-${data.id}`}
                className={styles.customHandle}
                isConnectable={true}
                style={{
                    top: `${outputInitialTopOffset + ((handleHeight + handleSpacing) * index)}px`,
                    background: `${nodeManager.colorByHandleName(outputName)}`
                }}
                data-tooltip-id="DefaultNodeToolTip"
                data-tooltip-content={outputName}
            />
        );
    }), [data.output, nodeHeight, handleHeight, handleSpacing]);

    const handleSetComplete = () => {
        if (!isConnected) return;
        if (nodeState === CustomNodeStatus.COMPLETED) {
            setNodeState(CustomNodeStatus.PREPARING);
            const new_data = {
                ...data,
                status: CustomNodeStatus.PREPARING
            };
            data.onChange(new_data);
        } else {
            setNodeState(CustomNodeStatus.COMPLETED);
            const new_data = {
                ...data,
                status: CustomNodeStatus.COMPLETED
            };
            data.onChange(new_data);
        }
        setTimeout(() => {
            updateNodeInternals(data.id);
        }, 500);
    };

    const nodeHeaderColor = () => {
        return NodeManagerClass.colorByNodeCategory(data.category)
    }

    return (
        <div className={`${nodeClassName}`} ref={nodeRef}>
            {nodeHeight && inputHandles}
            <div className={`${styles.node_header} customDragHandle`}
                 style={{cursor: 'grab', borderLeft: `4px solid ${nodeHeaderColor()}`}}>
                <div className={styles.title}>{data.label}</div>
                <div className={styles.close_node} onClick={handleCloseClick}><img
                    src="/images/aiflow/icon_node_close.svg"/></div>
            </div>
            <div className={styles.node_content}>
                <div className={styles.slider_container}>
                    <div className={styles.slider_track} ref={sliderRef}>
                        <div
                            className={styles.slider_track_before}
                            style={{width: `${handlePosition}px`}}
                        ></div>
                        <div
                            className={styles.slider_handle}
                            style={{left: `${handlePosition}px`}}
                            onMouseDown={handleMouseDown}
                        ></div>
                    </div>
                    <div className={styles.slider_value_container}>
                        {isEditing ? (
                            <input
                                type="number"
                                value={sliderValue}
                                step="0.01"
                                onChange={handleValueChange}
                                onBlur={handleBlur}
                                onKeyDown={handleKeyDown}
                                className={styles.slider_value_input}
                                autoFocus
                                disabled={!isConnected || nodeState === CustomNodeStatus.COMPLETED}
                            />
                        ) : (
                            <div
                                className={styles.slider_value_display}
                                onClick={() => isConnected && setIsEditing(true)}
                            >
                                {sliderValue.toFixed(2)}
                            </div>
                        )}
                    </div>
                </div>

                <button
                    className={`${styles.set_complete} ${nodeState === CustomNodeStatus.COMPLETED ? styles.set_data : isConnected ? styles.unset_data : styles.set_data}`}
                    onClick={handleSetComplete}
                    disabled={!isConnected}
                >
                    {!isConnected
                        ? 'Connect this node to another first'
                        : nodeState === CustomNodeStatus.COMPLETED
                            ? 'Edit'
                            : 'Complete'
                    }
                </button>

            </div>
            {nodeHeight && outputHandles}
            <Tooltip id="DefaultNodeToolTip" opacity="0.8"/>
            <div className={styles.node_footer}></div>
        </div>
    );

};

export default React.memo(CustomNode);
