import {IFlowNodeSource} from "../../interfaces/d";
import styles from "../../views/AIFlow.module.scss";
import React from "react";
import {onDragStart} from "./NodeListComponent";

export const nodeIcons: { [key: string]: string } = {
    "ImageLoader": "/images/aiflow/icon_image_loader.svg",
    "TextInput": "/images/aiflow/icon_text_input.svg",
    "FloatInput": "/images/aiflow/icon_float_input.svg",
    "TextViewer": "/images/aiflow/icon_text_viewer.svg",
    "ImageViewer": "/images/aiflow/icon_image_viewer.svg",
    // "video_loader": "/images/aiflow/icon_video_loader.svg",
};

export const IONodes = (_IONodeData: IFlowNodeSource[]) => {
    // Sort the IONodeData based on node_name
    const sortedIONodeData = _IONodeData.sort((a, b) => {
        const aName = a.node_name.toLowerCase();
        const bName = b.node_name.toLowerCase();

        if (aName.startsWith("image") && !bName.startsWith("image")) return -1;
        if (!aName.startsWith("image") && bName.startsWith("image")) return 1;

        if (aName.startsWith("text") && !bName.startsWith("text")) return -1;
        if (!aName.startsWith("text") && bName.startsWith("text")) return 1;

        return aName.localeCompare(bName);
    });

    return (
        <>
            {sortedIONodeData.map((model, index) => {
                const serialized_data = JSON.stringify({
                    node_name: model.node_name,
                    node_type: model.node_type,
                    model_id: model.model_id,
                    category: model.category,
                });

                const iconSrc = nodeIcons[model.node_type] || "/images/aiflow/icon_image_loader.svg";
                return (
                    <div
                        draggable
                        onDragStart={(event) => onDragStart(event, serialized_data)}
                        className={`${styles.io_nodes}`}
                        key={index}
                    >
                        <div className={styles.io_node_name}>{model.node_name}</div>
                        <div className={styles.io_node_icon}>
                            <img src={iconSrc} alt={`${model.node_name} icon`}/>
                        </div>
                    </div>
                );
            })}
        </>
    );
};
