import React from 'react';
import styles from './ExecuteApplicationIcon.module.scss';

import {useAuth} from "../../services/authService";
import {Tooltip} from "react-tooltip";

interface IconProps {
    onClick?: () => void;
}

const ExecuteApplicationIcon: React.FC<IconProps> = ({onClick}) => {
    const auth = useAuth();
    return (
        <>
            <img src="/images/aiflow/icon_execute_application.svg"
                 className={styles.bottom_execute_icon} onClick={onClick}
                 data-tooltip-id="executeTooltip"
                 data-tooltip-content={!auth.user ? "Please sign in to access this feature" : "Coming soon"}
                 data-totltip-place="left"
                 alt="Execute Applicaion Icon" width="120" height="120"/>

            <Tooltip id="executeTooltip" hidden={auth.user ? true : false}/>
        </>
    );
};

export default ExecuteApplicationIcon;
